import {
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'
import React from 'react'

export default function LoadingTable() {
  return (
    <TableContainer component={Paper} style={{ marginTop: 10 }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <LinearProgress />
            </TableCell>
            <TableCell>
              <LinearProgress />
            </TableCell>
            <TableCell>
              <LinearProgress />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>
              <LinearProgress />
            </TableCell>
            <TableCell>
              <LinearProgress />
            </TableCell>
            <TableCell>
              <LinearProgress />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <LinearProgress />
            </TableCell>
            <TableCell>
              <LinearProgress />
            </TableCell>
            <TableCell>
              <LinearProgress />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}
