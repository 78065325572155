import { useReducer } from 'react'
import { ALERT_MESSAGE } from '../utils/constants'

const actionTypes = {
  success: 'success',
  error: 'error',
  reset: 'reset',
}

function reducer(state, action) {
  switch (action.type) {
    case actionTypes.success:
      return { message: action.message || ALERT_MESSAGE.success, open: true, success: true }
    case actionTypes.error:
      return { message: action.message || ALERT_MESSAGE.error, open: true, success: false }
    case actionTypes.reset:
      return { ...state, open: false }
    default:
      return state
  }
}

export function useAlert() {
  const [state, dispatch] = useReducer(reducer, { message: '', open: false, success: true })
  const setSuccess = (successMessage = '') => dispatch({ type: actionTypes.success, message: successMessage })
  const setError = (errorMessage = '') => dispatch({ type: actionTypes.error, message: errorMessage })
  const setReset = () => dispatch({ type: actionTypes.reset })

  return { alert: state, setSuccess, setError, setReset }
}
