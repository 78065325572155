import { Grid } from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'
import NavBar from '../../NavBar'

MainLayout.propTypes = {
  children: PropTypes.element,
  user: PropTypes.object,
  onSignOut: PropTypes.func,
}

export default function MainLayout({ children, user, onSignOut }) {
  return (
    <Grid container>
      <NavBar user={user} onSignOut={onSignOut} />
      <Grid item xs={12}>
        <main>{children}</main>
      </Grid>
    </Grid>
  )
}
