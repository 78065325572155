import { Box, CircularProgress } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import DoneIcon from '@material-ui/icons/Done'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'

FileCheck.propTypes = {
  file: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  checkFile: PropTypes.func.isRequired,
  onChecked: PropTypes.func.isRequired,
}

export default function FileCheck({ label, file, checkFile, onChecked }) {
  const [check, setCheck] = useState(null)

  useEffect(() => {
    checkFile(file)
      .then((res) => {
        setCheck(res)
        onChecked(res)
      })
      .catch((_err) => {
        setCheck(false)
        onChecked(false)
      })
  }, [])

  const selectIcon = () => {
    if (check === null) return <CircularProgress />
    if (check === true) return <DoneIcon style={{ color: 'green' }} />
    if (check === false) return <CloseIcon style={{ color: 'red' }} />
  }

  return (
    <Box>
      {selectIcon()} {label}
    </Box>
  )
}
