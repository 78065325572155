import { Box, Link, useMediaQuery, useTheme } from '@material-ui/core'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import PropTypes from 'prop-types'
import React from 'react'
import './NavSteps.css'

NavSteps.propTypes = {
  firstStep: PropTypes.string.isRequired,
  secondStep: PropTypes.string.isRequired,
  onGoBackClick: PropTypes.func.isRequired,
}

export default function NavSteps({ firstStep, secondStep, onGoBackClick }) {
  const theme = useTheme()
  const isSmMedia = useMediaQuery(theme.breakpoints.up('sm'))

  return (
    <>
      {isSmMedia ? (
        <Box display="flex">
          <Link color="initial" onClick={onGoBackClick} className="step-text last-step">
            {firstStep}
          </Link>
          <Box mt={0.8}>
            <NavigateNextIcon />
          </Box>
          <Box className="step-text">{secondStep}</Box>
        </Box>
      ) : (
        <Box className="step-text" display="flex" justifyContent="center">
          {secondStep}
        </Box>
      )}
    </>
  )
}
