import { Box, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'
import './WelcomeBox.css'

WelcomeBox.propTypes = {
  children: PropTypes.element,
  subtitle: PropTypes.string.isRequired,
}

export default function WelcomeBox({ subtitle, children }) {
  return (
    <Box className="home-title" p={7} mb={5}>
      <Typography variant="h4" gutterBottom>
        Bonjour,
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        {subtitle}
      </Typography>
      {children}
    </Box>
  )
}
