import { Box, IconButton, Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core'
import DownloadIcon from '@material-ui/icons/GetApp'
import PropTypes from 'prop-types'
import downloadFile from '../../../services/file/download'
import { FILE_TYPES } from '../../../utils/constants'
import { formatDate, monthYearDate } from '../../../utils/format/date'
import LoadingTable from '../../UI/LoadingTable'
import Title from '../../UI/Title'

HistoryTable.propTypes = {
  files: PropTypes.array,
}

export default function HistoryTable({ files }) {
  return (
    <>
      <Title text="Fichiers" variant="body2" className="dialog" />
      {files === undefined ? (
        <LoadingTable />
      ) : (
        <TableContainer style={{ paddingBottom: '50px' }}>
          <Table>
            <TableBody>
              {files?.map((fileData) => (
                <TableRow key={fileData.file?.id}>
                  <TableCell>{`${fileData.file?.url.split('/')[0]} - ${monthYearDate(fileData.period)}`}</TableCell>
                  <TableCell>
                    <Box fontStyle="italic">chargé le {formatDate(fileData.file?.creationDate)}</Box>
                  </TableCell>
                  <TableCell align="right">
                    <IconButton
                      aria-label="download"
                      onClick={() => downloadFile(fileData.file?.url, FILE_TYPES.statement)}
                    >
                      <DownloadIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  )
}
