import { Box, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'

ErrorMessage.propTypes = {
  error: PropTypes.shape({
    status: PropTypes.bool.isRequired,
    message: PropTypes.string.isRequired,
  }),
}

export default function ErrorMessage({ error }) {
  return (
    <>
      {error.status && (
        <Box mb={2} color="red">
          <Typography variant="body2">{error.message}</Typography>
        </Box>
      )}
    </>
  )
}
