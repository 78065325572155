import { Box, Checkbox, Divider, FormControl, FormControlLabel, Grid, TextField } from '@material-ui/core'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import routes from '../../../routes'
import { CONTRACT_STATUS } from '../../../utils/constants'
import DialogInfo from '../../UI/DialogInfo'
import ValidationWrapper from '../../UI/Form/ValidationWrapper'
import { handleContractSatusValidation } from '../common'

ContractRefusalDialog.propTypes = {
  contract: PropTypes.object.isRequired,
  opened: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onValidate: PropTypes.func.isRequired,
}

export default function ContractRefusalDialog({ contract, opened, onClose, onValidate }) {
  const initialState = () => ({
    client: { checked: false, label: `Le document ne concerne pas le bon client` },
    reading: { checked: false, label: `Le document est illisible` },
    signature: { checked: false, label: `Le document n'est pas signé correctement` },
    format: { checked: false, label: `Il manque des informations dans le document` },
    dates: { checked: false, label: `Les dates du contrat ne sont pas correctes` },
    businessUnit: { checked: false, label: `Le contrat n'est pas rattaché à la bonne BU` },
  })
  const [state, setState] = useState(initialState)
  const [message, setMessage] = useState('')
  const { client, reading, signature, format, dates, businessUnit } = state

  useEffect(() => {
    let reasons = ''
    let verify = ''
    if (Object.values(state).some((value) => value.checked === true)) {
      reasons = '\nLes raisons sont les suivantes :\n'
      verify = 'ces points puis nous refaire parvenir '
      Object.values(state).forEach((value) => {
        if (value.checked) reasons += '\u2022 ' + value.label + '\n'
      })
    }
    const contractPath = routes.find((route) => route.name === 'contract').path
    const message = `Cher partenaire,\n\nLe contrat concernant ${contract.salesPoint.name} que vous avez transmis n'a malheureusement pas pu être validé par nos équipes.\n${reasons}\nVeuillez vérifier ${verify}ce contrat pour une nouvelle étude.\nVous pouvez accéder à l'outil en suivant ce lien : ${process.env.REACT_APP_URL}${contractPath}\n\nBien cordialement,\nLes équipes France Boissons`
    setMessage(message)
  }, [contract, state])

  const _handleChange = (event) =>
    setState({ ...state, [event.target.name]: { ...state[event.target.name], checked: event.target.checked } })

  const _handleValidate = async (contract, statusId, message) => {
    const { newContract, error } = await handleContractSatusValidation(contract, statusId, message)
    onValidate(newContract, error)
    onClose()
  }

  return (
    <DialogInfo title="Refuser un contrat" open={opened} onClose={onClose}>
      <ValidationWrapper
        button="Refuser et envoyer"
        onValidate={() => _handleValidate(contract, CONTRACT_STATUS.refused.id, message)}
      >
        <Box mb={2}>
          Vous êtes sur le point de refuser un contrat. Afin de permettre au fournisseur de corriger son contrat
          veuillez indiquer les points bloquants :
          <FormControl component="fieldset" style={{ margin: 3 }}>
            <Grid container>
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={<Checkbox checked={client.checked} onChange={_handleChange} name="client" />}
                  label={client.label}
                />
                <FormControlLabel
                  control={<Checkbox checked={signature.checked} onChange={_handleChange} name="signature" />}
                  label={signature.label}
                />
                <FormControlLabel
                  control={<Checkbox checked={dates.checked} onChange={_handleChange} name="dates" />}
                  label={dates.label}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={<Checkbox checked={reading.checked} onChange={_handleChange} name="reading" />}
                  label={reading.label}
                />
                <FormControlLabel
                  control={<Checkbox checked={format.checked} onChange={_handleChange} name="format" />}
                  label={format.label}
                />
                <FormControlLabel
                  control={<Checkbox checked={businessUnit.checked} onChange={_handleChange} name="businessUnit" />}
                  label={businessUnit.label}
                />
              </Grid>
            </Grid>
          </FormControl>
          <Divider />
          <Box my={2}>
            N&lsquo;hésitez pas à corriger message ci-dessous. Il sera envoyé au fournisseur pour lui indiquer le refus
            de son contrat.
          </Box>
          <Box mb={2}>
            <TextField
              id="emailMessage"
              multiline
              rows={6}
              fullWidth
              value={message}
              variant="outlined"
              onChange={(e) => setMessage(e.target.value)}
            />
          </Box>
        </Box>
      </ValidationWrapper>
    </DialogInfo>
  )
}
