import { List, ListItem, ListItemText } from '@material-ui/core'
import PropTypes from 'prop-types'
import './ActionList.css'

ActionList.propTypes = {
  items: PropTypes.array,
  currentItem: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  disablePadding: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
}

ActionList.defaultProps = {
  disablePadding: false,
}

export default function ActionList({ items, currentItem, disablePadding, onClick }) {
  const _selected = (id) => {
    if (Array.isArray(currentItem)) return currentItem.some((item) => item.id === id)
    else return currentItem.id === id
  }
  return (
    <List component="div" disablePadding={disablePadding}>
      {items?.map((item) => (
        <ListItem
          key={item.id}
          button
          onClick={() => onClick(item)}
          selected={_selected(item.id)}
          className="selected-item"
          disabled={item.disabled !== undefined ? item.disabled : false}
        >
          <ListItemText primary={item.name} className="item-text" />
        </ListItem>
      ))}
    </List>
  )
}
