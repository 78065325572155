import { Button, Checkbox, Collapse, List, ListItem, ListItemText } from '@material-ui/core'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import PropTypes from 'prop-types'
import { useState } from 'react'
import ActionList from '../../ActionList'
import Title from '../../Title'

CategorizedSubMenu.propTypes = {
  items: PropTypes.array.isRequired,
  selectedItems: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default function CategorizedSubMenu({ items, selectedItems, title, onChange }) {
  const [open, setOpen] = useState([true].concat(Array(items.length > 1 ? items.length - 1 : 0).fill(false)))

  function _handleOnItemClick(newItem) {
    const newSelectedItems = !selectedItems.includes(newItem.id)
      ? [...selectedItems, newItem.id]
      : selectedItems.filter((id) => id !== newItem.id)
    onChange(newSelectedItems)
  }

  function _handleOnCategoryClick(newItems) {
    if (newItems.every((subItem) => selectedItems.includes(subItem.id)))
      onChange(newItems.filter((subItem) => !selectedItems.includes(subItem.id)).map((item) => item.id))
    else onChange([...selectedItems, ...newItems.map((item) => item.id)])
  }

  const _handleOnSelectAllClick = () => onChange(items.map((item) => item.data.map((subItem) => subItem.id)).flat())

  return (
    <>
      <Title text={title} variant="body2" className="menu" />
      <List>
        {items?.map((item, index) => (
          <div key={item.id}>
            <ListItem disableGutters button>
              <Checkbox
                name="select-all"
                color="primary"
                checked={item.data.every((subItem) => selectedItems.includes(subItem.id))}
                onChange={() => _handleOnCategoryClick(item.data)}
              />
              <ListItemText
                primary={item.name}
                onClick={() => setOpen(Object.values({ ...open, [index]: !open[index] }))}
              />
              {open[index] ? (
                <ExpandLess onClick={() => setOpen(Object.values({ ...open, [index]: !open[index] }))} />
              ) : (
                <ExpandMore onClick={() => setOpen(Object.values({ ...open, [index]: !open[index] }))} />
              )}
            </ListItem>
            <Collapse in={open[index]} timeout="auto">
              <ActionList
                items={item.data}
                currentItem={selectedItems.map((item) => ({ id: item, name: item }))}
                disablePadding
                onClick={(item) => _handleOnItemClick(item)}
              />
            </Collapse>
          </div>
        ))}
      </List>
      <Button fullWidth color="primary" variant="contained" onClick={_handleOnSelectAllClick}>
        Tout sélectionner
      </Button>
    </>
  )
}
