import PropTypes from 'prop-types'
import { useState } from 'react'
import { REGIONS, ROLES } from '../../../utils/constants'
import NavSteps from '../../UI/NavSteps'
import UserCard from '../../UI/UserCard'
import AddSupplierContactDialog from '../AddSupplierContactDialog'
import SupplierOwner from '../SupplierOwner'

SuppliersEditSettingsView.propTypes = {
  currentSupplier: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  onGoBackClick: PropTypes.func.isRequired,
  onSupplierContactSaved: PropTypes.func.isRequired,
  onSupplierUpdated: PropTypes.func.isRequired,
}

const initialDialog = () => ({ open: false, role: 0, region: null, isBackup: false, userToEdit: null })

export default function SuppliersEditSettingsView({
  currentSupplier,
  title,
  onGoBackClick,
  onSupplierContactSaved,
  onSupplierUpdated,
}) {
  const [contactDialog, setContactDialog] = useState(initialDialog)
  const [supplier, setSupplier] = useState(currentSupplier)

  function supplierContactSaved(supplier, error) {
    if (!error.status) {
      setSupplier(supplier)
      setContactDialog(initialDialog)
    }
    onSupplierContactSaved(error)
  }

  return (
    <>
      <NavSteps firstStep={title} secondStep={supplier.name} onGoBackClick={onGoBackClick} />
      <SupplierOwner
        ownerId={currentSupplier.owner.id}
        supplierId={currentSupplier.id}
        onOwnerUpdated={(result) => onSupplierUpdated({ ...supplier, owner: result.owner })}
      />
      {/* Supplier contact */}
      <UserCard
        title="Déclaration des CA"
        users={[supplier.contact ?? {}, supplier.backupContact ?? {}]}
        onAddUser={(isBackup) => {
          setContactDialog({ open: true, role: ROLES.supplier, region: null, isBackup })
        }}
        onEditUser={(user, isBackup) =>
          setContactDialog({ open: true, role: ROLES.supplier, region: null, userToEdit: user, isBackup })
        }
      />
      {/* Regions contact */}
      {REGIONS.map((region) => {
        const users = supplier.regionUsers.find((regionUsers) => regionUsers.id === region.id)
        return (
          <UserCard
            key={region.id}
            title={`Contrats ${region.name}`}
            users={[users?.contact ?? {}, users?.backupContact ?? {}]}
            onAddUser={(isBackup) => {
              setContactDialog({ open: true, role: ROLES.RAV, region: region.id, isBackup })
            }}
            onEditUser={(user, isBackup) =>
              setContactDialog({ open: true, role: ROLES.RAV, region: region.id, userToEdit: user, isBackup })
            }
          />
        )
      })}

      {contactDialog.open && (
        <AddSupplierContactDialog
          isBackup={contactDialog.isBackup}
          opened={contactDialog.open}
          role={contactDialog.role}
          region={contactDialog.region}
          supplier={supplier}
          userToEdit={contactDialog.userToEdit}
          onClose={() => setContactDialog(initialDialog)}
          onValidate={supplierContactSaved}
        />
      )}
    </>
  )
}
