import { Box, Grid, IconButton, Typography } from '@material-ui/core'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import EditIcon from '@material-ui/icons/Edit'
import DownloadIcon from '@material-ui/icons/GetApp'
import VisibilityIcon from '@material-ui/icons/Visibility'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useAlert } from '../../../hooks/useAlert'
import downloadFile from '../../../services/file/download'
import { FILE_TYPES } from '../../../utils/constants'
import { formatDate, monthDate, monthYearDate } from '../../../utils/format/date'
import { sortObjectsArray } from '../../../utils/functions/common'
import ActionCard from '../../UI/ActionCard'
import Alert from '../../UI/Alert'
import Subtitle from '../../UI/SubTitle'
import DialogAgreement from '../SupplierAgreementDialog'
import SupplierCard from '../SupplierCard'
import DialogHistory from '../SupplierHistoryDialog'
import DialogPeriod from '../SupplierPeriodDialog'
import DialogUpload from '../SupplierUploadDialog'

SupplierView.propTypes = {
  supplier: PropTypes.object.isRequired,
  onAgreementDelete: PropTypes.func.isRequired,
}

export default function SupplierView({ supplier, onAgreementDelete }) {
  const history = useHistory()
  const { alert, setError, setReset, setSuccess } = useAlert()
  const [lastAgreement, setLastAgreement] = useState({})
  const [lastStatement, setLastStatement] = useState({})
  const [openPeriodUpdate, setOpenPeriodUpdate] = useState(false)
  const [openLastAgreement, setOpenLastAgreement] = useState(false)
  const [openAgreementUpload, setOpenAgreementUpload] = useState(false)
  const [openAgreementsHistory, setOpenAgreementsHistory] = useState(false)

  useEffect(() => {
    const agreements = sortObjectsArray([...supplier.agreements], 'periodStart')
    const lastAgreement = agreements.length === 0 ? {} : agreements[agreements.length - 1]
    setLastAgreement(lastAgreement)
  }, [supplier])

  useEffect(() => {
    const lastStatement = supplier.statements.length === 0 ? {} : supplier.statements[supplier.statements.length - 1]
    setLastStatement(lastStatement)
  }, [supplier])

  function _handleUpdatePeriod(updatedAgreement, error) {
    if (error.status) {
      setError()
      return
    }
    setLastAgreement(updatedAgreement)
    setSuccess()
  }

  function _handleDeleteAgreement(agreementId, error) {
    if (error.status) {
      setError(error.message)
      return
    }
    setSuccess('Accord supprimé')
    onAgreementDelete(agreementId)
  }

  return (
    <div>
      <Alert onClose={setReset} open={alert.open} success={alert.success} message={alert.message} />
      {/* Supplier details */}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Subtitle text={supplier.name} />
          <Box mb={3}>
            <Typography variant="body2" gutterBottom>
              {supplier.address?.address.toLowerCase()} {supplier.address?.zipCode} {supplier.address?.city}
              <br />
              {supplier.contact?.firstname} {supplier.contact?.lastname}
              <br />
              {supplier.contact?.email}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box textAlign="right">
            <Typography variant="body2" display="block" gutterBottom>
              <Box component="span" fontWeight="fontWeightBold">
                Acheteur
              </Box>
              <br />
              {supplier.owner?.firstname} {supplier.owner?.lastname}{' '}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      {/* Periods */}
      <Grid container>
        <Grid item xs={12} sm={6}>
          <SupplierCard
            title="Periodicité"
            subtitle={Object.entries(lastAgreement).length > 0 ? lastAgreement.type : 'Aucune périodicité déclarée'}
            content={
              lastAgreement !== null
                ? `de ${monthDate(lastAgreement.periodStart)} à ${monthDate(lastAgreement.periodEnd)}`
                : ''
            }
          >
            <>
              {lastAgreement.coopGroups?.length > 0 && (
                <IconButton aria-label="modifier la périodicité" onClick={() => setOpenPeriodUpdate(true)}>
                  <EditIcon />
                </IconButton>
              )}
            </>
          </SupplierCard>
        </Grid>
      </Grid>
      {/* Agreements */}
      <Grid container>
        <Grid item xs={12} sm={6}>
          <SupplierCard
            title="Accords"
            subtitle={`${lastAgreement?.coopGroups?.length ?? 0} groupe d'accord${
              supplier.agreements.length > 1 ? 's' : ''
            }`}
            content={
              Object.entries(lastAgreement).length === 0
                ? "Aucun groupe d'accord"
                : `mise à jour ${formatDate(lastAgreement.annex.creationDate)}`
            }
          >
            <>
              {lastAgreement?.coopGroups?.length > 0 && (
                <IconButton aria-label="voir le dernier accord" onClick={() => setOpenLastAgreement(true)}>
                  <VisibilityIcon />
                </IconButton>
              )}
            </>
          </SupplierCard>
        </Grid>
        <Grid item container xs={12} sm={6}>
          <Grid item sm={1}></Grid>
          <Grid item xs={12} sm={10}>
            <ActionCard
              items={[{ text: 'Importer un nouveau fichier' }]}
              onClick={() => setOpenAgreementUpload(true)}
            />
          </Grid>
          <Grid item sm={1}></Grid>
          <Grid item sm={1}></Grid>
          <Grid item xs={12} sm={10}>
            <ActionCard items={[{ text: 'Historique des accords' }]} onClick={() => setOpenAgreementsHistory(true)} />
          </Grid>
        </Grid>
      </Grid>
      {/* Last statement */}
      <Grid container>
        <Grid item xs={12} sm={6}>
          <SupplierCard
            title="Dernière déclaration"
            subtitle={
              Object.entries(lastStatement).length > 0 ? monthYearDate(lastStatement.period) : 'Aucune déclaration'
            }
          >
            <>
              {Object.entries(lastStatement).length > 0 && (
                <IconButton
                  aria-label="télécharger la dernière déclaration"
                  onClick={() => downloadFile(lastStatement.file.url, FILE_TYPES.statement)}
                >
                  <DownloadIcon />
                </IconButton>
              )}
            </>
          </SupplierCard>
        </Grid>
      </Grid>
      {/* Contracts */}
      <Grid container>
        <Grid item xs={12} sm={6}>
          <SupplierCard title="Contrats" subtitle={'Contrats'}>
            <IconButton
              aria-label="contrats"
              onClick={() =>
                history.push({
                  pathname: '/backoffice/contracts',
                  state: { supplier: { id: supplier.id, name: supplier.name } },
                })
              }
            >
              <ChevronRightIcon />
            </IconButton>
          </SupplierCard>
        </Grid>
      </Grid>
      {openPeriodUpdate && (
        <DialogPeriod
          agreement={lastAgreement}
          opened={openPeriodUpdate}
          onClose={() => setOpenPeriodUpdate(false)}
          onValidate={(updatedAgreement, error) => _handleUpdatePeriod(updatedAgreement, error)}
        />
      )}
      {openLastAgreement && (
        <DialogAgreement
          agreement={lastAgreement}
          opened={openLastAgreement}
          onClose={() => setOpenLastAgreement(false)}
        />
      )}
      {openAgreementUpload && (
        <DialogUpload
          opened={openAgreementUpload}
          supplierName={supplier.name}
          onClose={() => setOpenAgreementUpload(false)}
          onValidate={(error) => (error.status ? setError() : setSuccess(`Fichier en cours d'import`))}
        />
      )}
      {openAgreementsHistory && (
        <DialogHistory
          opened={openAgreementsHistory}
          supplier={supplier}
          onClose={() => setOpenAgreementsHistory(false)}
          onDelete={(agreementId, error) => _handleDeleteAgreement(agreementId, error)}
        />
      )}
    </div>
  )
}
