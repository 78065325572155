import { Box, Button } from '@material-ui/core'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { checkAmount, checkCoopGroups, checkData, checkFileFormat } from '../../../services/excel/checkStatementUpload'
import FileCheck from '../../UI/FileCheck'
import Subtitle from '../../UI/SubTitle'
import FileSum from '../StatementFileSum/index.jsx'

StatementCheck.propTypes = {
  agreement: PropTypes.object.isRequired,
  file: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default function StatementCheck({ agreement, file, title, onClick }) {
  const [formatStatus, setFormatStatus] = useState(false)
  const [dataStatus, setDataStatus] = useState(false)
  const [coopGroupsStatus, setCoopGroupsStatus] = useState(false)
  const [amountStatus, setAmountStatus] = useState(false)

  return (
    <div>
      <Subtitle text={title} />
      {agreement && (
        <Box>
          <FileCheck
            file={file}
            checkFile={(testedFile) => checkFileFormat(testedFile.name)}
            label="Vérification du format du fichier"
            onChecked={(checked) => setFormatStatus(checked)}
          />
          <FileCheck
            file={file}
            checkFile={(testedFile) => checkData(testedFile)}
            label="Vérification de la structure du fichier"
            onChecked={(checked) => setDataStatus(checked)}
          />
          <FileCheck
            file={file}
            checkFile={(testedFile) => checkCoopGroups(testedFile, agreement)}
            label="Vérification des groupes d'accords"
            onChecked={(checked) => setCoopGroupsStatus(checked)}
          />
          <FileCheck
            file={file}
            checkFile={(testedFile) => checkAmount(testedFile, agreement.coopGroups)}
            label="Vérification des montants"
            onChecked={(checked) => setAmountStatus(checked)}
          />
          {[formatStatus, dataStatus, coopGroupsStatus, amountStatus].every((status) => status === true) && (
            <FileSum file={file} agreement={agreement} />
          )}
        </Box>
      )}
      <Box style={{ marginTop: '25px' }} display="flex" flexDirection="row-reverse">
        <Box>
          <Button
            variant="contained"
            disabled={![formatStatus, dataStatus, coopGroupsStatus, amountStatus].every((status) => status === true)}
            disableElevation
            color="secondary"
            onClick={() => onClick(3)}
          >
            Valider
          </Button>
        </Box>
      </Box>
    </div>
  )
}
