import PropTypes from 'prop-types'
import ActionList from '../../ActionList'
import Title from '../../Title'

SubMenu.propTypes = {
  currentItem: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  items: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default function SubMenu({ currentItem, items, title, onClick }) {
  return (
    <>
      <Title text={title} variant="body2" className="menu" />
      <ActionList items={items} currentItem={currentItem} onClick={onClick} />
    </>
  )
}
