import PropTypes from 'prop-types'
import React from 'react'
import { useHistory } from 'react-router-dom'
import ActionCard from '../../ActionCard'
import Title from '../../Title'

MainMenu.propTypes = {
  items: PropTypes.array.isRequired,
  onClick: PropTypes.func,
}

export default function MainMenu({ items, onClick }) {
  const history = useHistory()

  function _onClick(path, data, customAction) {
    if (customAction) {
      onClick(path)
      return
    }

    history.push({
      pathname: path,
      state: data,
    })
  }

  return (
    <div>
      {items.map((item, index) => (
        <div key={`action-card-item-${index}`}>
          <Title text={item.title} variant="body2" className="menu" />
          <ActionCard items={item.action} onClick={_onClick} />
        </div>
      ))}
    </div>
  )
}
