import { Chip, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import { useEffect, useState } from 'react'
import { formatDate } from '../../../utils/format/date'
import { formatPercent } from '../../../utils/format/percent'
import { formatPrice } from '../../../utils/format/price'
import { sortObjectsArray } from '../../../utils/functions/common'
import DialogInfo from '../../UI/DialogInfo'

export default function SupplierAgreementDialog({ agreement, opened, onClose }) {
  const [coopGroups, setCoopGroups] = useState([])

  useEffect(() => {
    const coopGroups = sortObjectsArray([...agreement.coopGroups], 'name')
    setCoopGroups(coopGroups)
  }, [agreement])

  return (
    <DialogInfo
      title="Accord"
      subtitle={`mise à jour ${formatDate(agreement.annex.creationDate)}`}
      open={opened}
      onClose={onClose}
    >
      <TableContainer style={{ paddingBottom: '50px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Groupe d&apos;accord</TableCell>
              <TableCell>Taux FB SAS</TableCell>
              <TableCell>Taux FB Filiales</TableCell>
              <TableCell>Taux Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {coopGroups?.map((coopGroup, index) => {
              const amountSum = coopGroup.centralTax + coopGroup.regionTax
              return (
                <TableRow key={index}>
                  <TableCell>{coopGroup.name}</TableCell>
                  <TableCell>
                    <Chip
                      style={{ borderRadius: 0 }}
                      label={
                        coopGroup.isPercent ? formatPercent(coopGroup.centralTax) : formatPrice(coopGroup.centralTax)
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <Chip
                      style={{ borderRadius: 0 }}
                      label={
                        coopGroup.isPercent ? formatPercent(coopGroup.regionTax) : formatPrice(coopGroup.regionTax)
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <Chip
                      style={{ borderRadius: 0 }}
                      label={coopGroup.isPercent ? formatPercent(amountSum) : formatPrice(amountSum)}
                    />
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </DialogInfo>
  )
}
