import { Box, IconButton, Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import PropTypes from 'prop-types'
import { useState } from 'react'
import API from '../../../api/api'
import { deleteUserMutation } from '../../../api/mutations/userMutations'
import { ROLES_VALUES } from '../../../utils/constants'
import DialogInfo from '../../UI/DialogInfo'
import ValidationWrapper from '../../UI/Form/ValidationWrapper'

UsersSettingsTable.propTypes = {
  data: PropTypes.array.isRequired,
  displayRole: PropTypes.bool,
  onDelete: PropTypes.func,
}
UsersSettingsTable.defaultProps = {
  displayRole: false,
}

const initialState = () => ({ openDialog: false, userToDelete: null })

export default function UsersSettingsTable({ data, displayRole, onDelete }) {
  const [state, setState] = useState(initialState)

  const _deleteUser = async () => {
    try {
      await API.queryAPI(deleteUserMutation(state.userToDelete.id))
      onDelete(state.userToDelete, { status: false })
    } catch (errorMessage) {
      onDelete(null, { status: true, message: errorMessage })
    }
    setState(initialState)
  }

  return (
    <>
      <TableContainer>
        <Table>
          <TableBody>
            {data?.map((user, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Box component="span" style={{ textTransform: 'capitalize' }}>
                    {user.firstname}
                  </Box>
                </TableCell>
                <TableCell>
                  <Box component="span" style={{ textTransform: 'capitalize' }}>
                    {user.lastname}
                  </Box>
                </TableCell>
                <TableCell>{user.email}</TableCell>
                {displayRole && <TableCell>{ROLES_VALUES.find((role) => role.id === user.role).value ?? ''}</TableCell>}
                <TableCell>
                  <IconButton
                    aria-label="supprimer"
                    color="secondary"
                    onClick={() => setState({ openDialog: true, userToDelete: user })}
                    size="small"
                  >
                    <DeleteIcon fontSize="inherit" />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <DialogInfo title="" open={state.openDialog} onClose={() => setState(initialState)} fullWidth={false}>
        <ValidationWrapper onValidate={_deleteUser}>
          <Box mb={2}>
            Êtes-vous sûr·e de vouloir supprimer définitivement l&apos;utilisateur
            {state.userToDelete && (
              <Box style={{ textTransform: 'capitalize' }} component="span">
                {` ${state.userToDelete?.firstname.substring(0, 1)}. ${state.userToDelete?.lastname}`} ?
              </Box>
            )}
          </Box>
        </ValidationWrapper>
      </DialogInfo>
    </>
  )
}
