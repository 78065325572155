import FileSaver from 'file-saver'
import { REGIONS } from '../../utils/constants'
import { sortObjectsArray } from '../../utils/functions/common'
import { setBold } from './common'
import {
  addHeaderRow,
  addTableHeader,
  addTableRow,
  addTableTotalRow,
  setColumnsWidth,
} from './commonStatementFunctions'

const Excel = require('exceljs')

export default async function (supplier, period) {
  const workbook = new Excel.Workbook()
  // Set tab name
  const worksheet = workbook.addWorksheet('SYNTHESE', { views: [{ showGridLines: false }] })
  setColumnsWidth(worksheet)

  const headerCellsValues = {
    A: period,
    E: `CA et volume déclarés par ${supplier.name} par FB`,
    O: period,
    P: 'Remise de fin d’année FB filiales',
    Y: 'Remise de fin d’année FB SAS',
    AH: 'Montant Coop Total',
  }
  let row = 1
  addHeaderRow(worksheet, row, headerCellsValues, ['E', 'M', 'P', 'W', 'Y', 'AF', 'AH', 'AO'])
  addTableHeader(worksheet, row + 1)

  // Table content
  const coopGroups = sortObjectsArray(supplier.agreement.coopGroups, 'name')
  coopGroups.forEach((coopGroup, index) => {
    row = 3 + index
    addTableRow(worksheet, coopGroup, [0, 0, 0, 0, 0, 0, 0, 0], row)
  })

  row++
  addTableTotalRow(worksheet, 1, row)

  worksheet.addRow()
  worksheet.addRow()
  worksheet.addRow()
  worksheet.addRow()
  worksheet.addRow()
  row = row + 6

  worksheet.addRow(['Lexique :'])
  setBold(worksheet, row)
  REGIONS.map((region) => {
    worksheet.addRow([region.code, region.name])
  })

  // Add protection to prevent cells to be updated except for the amount
  await worksheet.protect('MNWx7t56aT5M')
  for (let i = 3; i < supplier.agreement.coopGroups.length + 3; i++) {
    const unlockedCells = [`E${i}`, `F${i}`, `G${i}`, `H${i}`, `I${i}`, `J${i}`, `K${i}`, `L${i}`]
    unlockedCells.forEach((cell) => {
      worksheet.getCell(cell).protection = { locked: false }
    })
  }

  const buffer = await workbook.xlsx.writeBuffer()
  FileSaver.saveAs(new Blob([buffer]), `Declaration ${supplier.name} ${period} vierge.xlsx`)
}
