import { Box } from '@material-ui/core'
import PropTypes from 'prop-types'
import './Subtitle.css'

Subtitle.propTypes = {
  text: PropTypes.string.isRequired,
}

export default function Subtitle({ text }) {
  return <Box className="subtitle">{text}</Box>
}
