import { Grid } from '@material-ui/core'
import PropTypes from 'prop-types'
import { useState } from 'react'
import API from '../../../api/api'
import { createBackOfficeUserMutation } from '../../../api/mutations/userMutations'
import { ROLES_VALUES } from '../../../utils/constants'
import DialogInfo from '../../UI/DialogInfo'
import CommonTextField from '../../UI/Form/CommonTextField'
import UserForm from '../../UI/Form/UserForm'
import ValidationWrapper from '../../UI/Form/ValidationWrapper'

AddUserDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onValidate: PropTypes.func.isRequired,
}

const initialUser = () => ({ firstname: '', lastname: '', email: '', role: 1 })
const initialValidity = () => ({ firstname: false, lastname: false, email: false })

export default function AddUserDialog({ open, onClose, onValidate }) {
  const [user, setUser] = useState(initialUser)
  const [fieldsValidity, setFieldsValidity] = useState(initialValidity)

  function _handleChange(value, name, isValid) {
    setUser({ ...user, [name]: value })
    setFieldsValidity({ ...fieldsValidity, [name]: isValid })
  }
  async function _handleNewContact() {
    try {
      const result = await API.queryAPI(
        createBackOfficeUserMutation({
          firstname: user.firstname.trim().toLowerCase(),
          lastname: user.lastname.trim().toLowerCase(),
          email: user.email.trim().toLowerCase(),
          role: user.role,
        })
      )
      onValidate(result.createBackOfficeUser, { status: false })
    } catch (errorMessage) {
      onValidate(null, { status: true, message: errorMessage })
    } finally {
      onClose()
    }
  }
  return (
    <DialogInfo title={'Ajouter un nouvel utilisateur'} open={open} onClose={onClose}>
      <ValidationWrapper fieldsValidity={fieldsValidity} onValidate={_handleNewContact}>
        <Grid container spacing={2}>
          <UserForm user={user} onChange={_handleChange} />
          <Grid item xs={6}>
            <CommonTextField
              name="role"
              value={user.role}
              onChange={(value) => setUser({ ...user, role: value })}
              label="Role"
              select={ROLES_VALUES}
              required
            />
          </Grid>
        </Grid>
      </ValidationWrapper>
    </DialogInfo>
  )
}
