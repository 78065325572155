import BackOffice from './BackOffice/BackOffice'
import Contracts from './BackOffice/Contracts/Contracts.jsx'
import History from './BackOffice/History/History.jsx'
import Settings from './BackOffice/Settings/Settings.jsx'
import Suppliers from './BackOffice/Suppliers/Suppliers.jsx'
import SupplierContracts from './Home/Contracts/Contracts.jsx'
import SupplierHistory from './Home/History/History.jsx'
import Home from './Home/Home'
import Statement from './Home/Statement/Statement.jsx'
import ResetPassword from './ResetPassword'
import UpdatePassword from './UpdatePassword'

export default {
  BackOffice,
  Contracts,
  History,
  Home,
  ResetPassword,
  Settings,
  Statement,
  Suppliers,
  SupplierContracts,
  SupplierHistory,
  UpdatePassword,
}
