import { Box, Card, CardActionArea, CardContent, Divider } from '@material-ui/core'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import PropTypes from 'prop-types'
import React from 'react'

ActionCard.propTypes = {
  items: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default function ActionCard({ items, onClick }) {
  return (
    <Card variant="outlined" style={{ marginBottom: 15 }}>
      {items.map((item, index) => (
        <CardActionArea key={index} onClick={() => onClick(item.path, item.data, !!item.customAction)}>
          {index > 0 && <Divider />}
          <CardContent>
            <Box display="flex" alignItems="center">
              <Box flexGrow={1} fontWeight="fontWeightBold">
                {item.text}
              </Box>
              <Box>{item.icon ?? <NavigateNextIcon />}</Box>
            </Box>
          </CardContent>
        </CardActionArea>
      ))}
    </Card>
  )
}
