import { Box, Grid, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import { formatPrice } from '../../../utils/format/price'
import Title from '../Title'
import './FigureCards.css'

FigureCards.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
}

export default function FigureCards({ title, items }) {
  return (
    <div>
      <Title text={title} variant="body2" className="dialog" />
      <Box mt={1}>
        <Grid container spacing={2}>
          {items.map((item, index) => (
            <Grid key={index} item xs={6}>
              <Box className="figure-card">
                <Typography variant="overline">{item.subtitle}</Typography>
                <Box display="flex" fontWeight="fontWeightBold" justifyContent="center">
                  {formatPrice(item.figure)}
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </div>
  )
}
