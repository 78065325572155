import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import API from '../../../api/api'
import { getAllSuppliersSettingsQuery } from '../../../api/queries/supplierQueries'
import { useAlert } from '../../../hooks/useAlert'
import { sortObjectsArray } from '../../../utils/functions/common'
import Alert from '../../UI/Alert'
import SuppliersEditSettingsView from '../SuppliersEditSettingsView'
import SuppliersSettingsView from '../SuppliersSettingsView'

SuppliersSettings.propTypes = {
  onDataLoaded: PropTypes.func.isRequired,
}

const TITLE = 'Gestion des fournisseurs'

export default function SuppliersSettings({ onDataLoaded }) {
  const { alert, setError, setReset, setSuccess } = useAlert()
  const [suppliers, setSuppliers] = useState([])
  const [displayMainView, setDisplayMainView] = useState(true)
  const [editingSupplier, setEditingSupplier] = useState({})

  useEffect(() => {
    API.queryAPI(getAllSuppliersSettingsQuery)
      .then((suppliersResult) => setSuppliers(sortObjectsArray(suppliersResult.suppliers, 'name')))
      .catch((errorMessage) => onDataLoaded(errorMessage))
  }, [])

  function _handleNewSupplier(newSupplier, error) {
    if (error.status) {
      setError(error.message)
      return
    }
    setSuppliers(sortObjectsArray([...suppliers, newSupplier], 'name'))
    setSuccess()
  }

  function _handleSupplierUpdated(updatedSupplier) {
    setSuppliers((prevSuppliers) =>
      prevSuppliers.map((supplier) => (supplier.id === updatedSupplier.id ? updatedSupplier : supplier))
    )
  }

  return (
    <>
      <Alert onClose={() => setReset()} open={alert.open} success={alert.success} message={alert.message} />
      {displayMainView ? (
        <SuppliersSettingsView
          suppliers={suppliers}
          title={TITLE}
          onAddSupplier={(newSupplier, error) => _handleNewSupplier(newSupplier, error)}
          onSupplierClick={(supplier) => {
            setEditingSupplier(supplier)
            setDisplayMainView(false)
          }}
        />
      ) : (
        <SuppliersEditSettingsView
          currentSupplier={editingSupplier}
          title={TITLE}
          onGoBackClick={() => {
            setDisplayMainView(true)
            setEditingSupplier({})
          }}
          onSupplierContactSaved={(error) => (error.status ? setError(error.message) : setSuccess())}
          onSupplierUpdated={(updatedSupplier) => _handleSupplierUpdated(updatedSupplier)}
        />
      )}
    </>
  )
}
