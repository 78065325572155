import { Typography } from '@material-ui/core'

export default function StatementNoAgreementError() {
  return (
    <Typography variant="body2" style={{ color: 'red' }}>
      Impossible d&apos;effectuer cette action tant qu&apos;aucun fichier d&apos;accord n&apos;a été importé pour cette
      période.
      <br />
      Veuillez contacter votre administrateur France Boissons.
    </Typography>
  )
}
