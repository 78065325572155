import { Box, Checkbox, FormControlLabel, Grid } from '@material-ui/core'
import { useState } from 'react'
import API from '../../../api/api'
import { updateAgreementFrequencyMutation } from '../../../api/mutations/agreementMutations'
import DialogInfo from '../../UI/DialogInfo'
import ValidationWrapper from '../../UI/Form/ValidationWrapper'

export default function SupplierPeriodDialog({ agreement, opened, onValidate, onClose }) {
  const [frequency, setFrequency] = useState(agreement.frequency)

  async function _handleNewType() {
    try {
      const result = await API.queryAPI(updateAgreementFrequencyMutation(agreement.id, frequency))
      onValidate(result.updateAgreementFrequency, { status: false })
    } catch (errorMessage) {
      onValidate({}, { status: true, message: errorMessage })
    } finally {
      onClose()
    }
  }

  const _handleChange = (event) => {
    setFrequency({ ...frequency, [event.target.name]: event.target.checked })
  }

  return (
    <DialogInfo title="Modification de la période" open={opened} onClose={onClose}>
      <ValidationWrapper onValidate={_handleNewType}>
        <Box ml={5}>
          <Grid container>
            <Grid item xs={3}>
              <FormControlLabel
                control={
                  <Checkbox color="primary" checked={frequency.january} onChange={_handleChange} name="january" />
                }
                label="Janvier"
              />
            </Grid>
            <Grid item xs={3}>
              <FormControlLabel
                control={
                  <Checkbox color="primary" checked={frequency.february} onChange={_handleChange} name="february" />
                }
                label="Février"
              />
            </Grid>
            <Grid item xs={3}>
              <FormControlLabel
                control={<Checkbox color="primary" checked={frequency.march} onChange={_handleChange} name="march" />}
                label="Mars"
              />
            </Grid>
            <Grid item xs={3}>
              <FormControlLabel
                control={<Checkbox color="primary" checked={frequency.april} onChange={_handleChange} name="april" />}
                label="Avril"
              />
            </Grid>
            <Grid item xs={3}>
              <FormControlLabel
                control={<Checkbox color="primary" checked={frequency.may} onChange={_handleChange} name="may" />}
                label="Mai"
              />
            </Grid>
            <Grid item xs={3}>
              <FormControlLabel
                control={<Checkbox color="primary" checked={frequency.june} onChange={_handleChange} name="june" />}
                label="Juin"
              />
            </Grid>
            <Grid item xs={3}>
              <FormControlLabel
                control={<Checkbox color="primary" checked={frequency.july} onChange={_handleChange} name="july" />}
                label="Juillet"
              />
            </Grid>
            <Grid item xs={3}>
              <FormControlLabel
                control={<Checkbox color="primary" checked={frequency.august} onChange={_handleChange} name="august" />}
                label="Août"
              />
            </Grid>
            <Grid item xs={3}>
              <FormControlLabel
                control={
                  <Checkbox color="primary" checked={frequency.september} onChange={_handleChange} name="september" />
                }
                label="Septembre"
              />
            </Grid>
            <Grid item xs={3}>
              <FormControlLabel
                control={
                  <Checkbox color="primary" checked={frequency.october} onChange={_handleChange} name="october" />
                }
                label="Octobre"
              />
            </Grid>
            <Grid item xs={3}>
              <FormControlLabel
                control={
                  <Checkbox color="primary" checked={frequency.november} onChange={_handleChange} name="november" />
                }
                label="Novembre"
              />
            </Grid>
            <Grid item xs={3}>
              <FormControlLabel
                control={
                  <Checkbox color="primary" checked={frequency.december} onChange={_handleChange} name="december" />
                }
                label="Décembre"
              />
            </Grid>
          </Grid>
        </Box>
      </ValidationWrapper>
    </DialogInfo>
  )
}
