import { AddressType, supplierAgreement, supplierHistoryType, UserType } from '../types'

export const supplierByUserQuery = (email) => {
  return `{
    supplierByUser(email: "${email}") {
      id
      name
    }
  }`
}

export const supplierStatementPeriodByUserQuery = (email) => {
  return `{
    supplierByUser(email: "${email}") {
      id
      name
      statements {
        id
        period
      }
    }
  }`
}

export const supplierAgreementByUserQuery = (email) => {
  return `{
    supplierByUser(email: "${email}") {
      id
      name
      agreements {
        id
        periodStart
        periodEnd
        coopGroups {
          id
          name
          regionTax
          centralTax
          isPercent
        }
      }
    }
  }`
}

export const getAllSuppliersStatementsQuery = `{
  suppliers {
    id
    name
    statements {
      period,
      file {
        creationDate
      }
    }
  }
}`

export const getAllSuppliersQuery = `{
  suppliers {
    id
    name
    ${AddressType}
    contact {
      firstname
      lastname
      email
    }
    owner {
      id
      firstname
      lastname
    }
    agreements {
      ${supplierAgreement}
    }
    statements {
      period
      file {
        url
      }
    }
  }
}`

export const getAllSuppliersHistoryQuery = `{
  suppliers ${supplierHistoryType}
}`

export const getSupplierHistoryByUserQuery = (email) => {
  return `{
    supplierByUser(email: "${email}") ${supplierHistoryType}
  }`
}

export const getAllSuppliersSettingsQuery = `{
  suppliers {
    id
    name
    ${UserType('owner')}
    ${UserType('contact')}
    ${UserType('backupContact')}
    regionUsers {
      id
      ${UserType('contact')}
      ${UserType('backupContact')}
    }
  }
}`
