export const FileType = `
  file {
    id
    name
    url
    creationDate
  }`

export const AddressType = `
address {
  address
  zipCode
  city
}`

export const UserType = (type) => `
${type} {
  id
  lastname
  firstname
  email
}`

export const StatusType = `
status {
  id
  name
  date
}`

export const BusinessUnitType = `{
  id
  code
  name
  region
}`

export const ContractType = `
{
  id
  startDate
  endDate
  expectedVolume
  hectoliters
  salesPoint {
    id
    code
    name
    ${AddressType}
    businessUnit ${BusinessUnitType}
  }
  ${StatusType}
  ${FileType}
}`

export const supplierHistoryType = `{
  id
  name
  owner {
    id
  }
  statements {
    id,
    period,
    amounts {
      id
      amount
      coopGroup {
        id
        name
        regionTax
        centralTax
        isPercent
        agreementId

      }
      region
    },
    ${FileType}
  }
}`

export const supplierAgreement = `
  id
  periodStart
  periodEnd
  type
  frequency {
    january
    february
    march
    april
    may
    june
    july
    august
    september
    october
    november
    december
  }
  coopGroups {
    name
    centralTax
    regionTax
    isPercent
  }
  annex {
    creationDate
    url
  }
`
