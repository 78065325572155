export const getUserByEmailQuery = (email) => {
  return `{
    user(email: "${email}") {
      email
      firstname
      lastname
      role
    }
  }`
}

export const getUserByRoleQuery = (role) => {
  return `{
    userByRole(role: ${role}) {
      id
      firstname
      lastname
      email
      role
    }
  }`
}
