import { Box, Button, Typography } from '@material-ui/core'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import API from '../../api/api'
import { askPasswordUpdateMutation } from '../../api/mutations/userMutations'
import ErrorMessage from '../../components/UI/ErrorMessage'
import CommonTextField from '../../components/UI/Form/CommonTextField'
import { useErrorState } from '../../hooks/useErrorState'

export default function ResetPassword() {
  const [email, setEmail] = useState('')
  const { error, setErrorState } = useErrorState()
  const history = useHistory()

  const askPasswordUpdate = async () => {
    try {
      await API.queryAPI(askPasswordUpdateMutation(email))
      history.replace('/')
    } catch (errorMessage) {
      setErrorState(errorMessage)
    }
  }

  return (
    <>
      <Box mb={2}>
        <Typography variant="h4">Mettre à jour votre mot de passe</Typography>
      </Box>
      <Box mb={2} variant="subtitle1">
        Afin de mettre à jour votre mot de passe, merci de nous communiquer votre adresse mail. Un e-mail vous sera
        alors envoyé avec un lien vers la procédure de mise à jour du mot de passe.
      </Box>
      <CommonTextField name="email" onChange={(value) => setEmail(value)} label="Email" required />
      <ErrorMessage error={error} />
      <Button onClick={askPasswordUpdate} variant="contained" color="secondary" fullWidth>
        Envoyer
      </Button>
    </>
  )
}
