import { Box } from '@material-ui/core'
import PropTypes from 'prop-types'
import { sortObjectsArray } from '../../../utils/functions/common'
import Layout from '../../Layout/BodyLayout'
import NavDetails from '../../UI/NavDetails'
import HistoryFigures from '../HistoryFigures'
import HistoryTable from '../HistoryTable'

HistoryView.propTypes = {
  menu: PropTypes.element.isRequired,
  selectedTurnovers: PropTypes.array.isRequired,
  turnovers: PropTypes.array.isRequired,
}

export default function HistoryView({ files, menu, selectedTurnovers, turnovers }) {
  return (
    <Layout navDetails={<NavDetails name="Historique" />} menu={menu}>
      <>
        <HistoryFigures selectedTurnovers={selectedTurnovers} turnovers={turnovers} />
        <Box mt={5}>
          <HistoryTable files={files ? sortObjectsArray([...files], 'period') : files} />
        </Box>
      </>
    </Layout>
  )
}
