import FileSaver from 'file-saver'
import { REGIONS } from '../../utils/constants'
import { formatDate, today } from '../../utils/format/date'
import { setBackGroundColor } from './common'
const Excel = require('exceljs')

export default async function (contracts) {
  const workbook = new Excel.Workbook()
  const worksheet = workbook.addWorksheet()
  const headerColor = 'FAC090'
  const cells = [
    { cell: 'A', title: 'Contrat' },
    { cell: 'B', title: 'Ville' },
    { cell: 'C', title: 'Région' },
    { cell: 'D', title: 'Site FB' },
    { cell: 'E', title: 'Code Client FB' },
    { cell: 'F', title: 'Date début' },
    { cell: 'G', title: 'Date Fin' },
    { cell: 'H', title: 'Statut' },
    { cell: 'I', title: 'Date Statut' },
    { cell: 'J', title: 'Volume' },
    { cell: 'K', title: 'Type volume' },
  ]
  // Columns and first row
  const columns = []
  for (let i = 0; i < cells.length; i++) columns.push({ header: cells[i].title, width: 18 })
  worksheet.columns = columns
  worksheet.getRow(1).font = { bold: true }
  cells.forEach((cell) => setBackGroundColor(worksheet, `${cell.cell}1`, headerColor))
  // Contract rows detail
  contracts.forEach((contract) => {
    worksheet.addRow([
      contract.salesPoint.name,
      REGIONS.find((region) => region.id === contract.salesPoint.businessUnit.region).name,
      contract.salesPoint.address.city,
      contract.salesPoint.businessUnit.code,
      contract.salesPoint.code,
      formatDate(contract.startDate),
      formatDate(contract.endDate),
      contract.status.name,
      contract.status.date,
      contract.expectedVolume,
      contract.hectoliters ? 'HL' : 'BOUTEILLES',
    ])
  })

  const buffer = await workbook.xlsx.writeBuffer()
  FileSaver.saveAs(new Blob([buffer]), `Export contrats ${today()}.xlsx`)
}
