import { Box } from '@material-ui/core'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { monthDate, monthYearDate, yearDate } from '../../../utils/format/date'
import { sortDates } from '../../../utils/functions/common'
import CommonTextField from '../../UI/Form/CommonTextField'

StatementSelectPeriods.propTypes = {
  agreements: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default function StatementSelectPeriods({ agreements, onChange }) {
  const [allPeriods, setAllPeriods] = useState([])
  const [months, setMonths] = useState([])
  const [years, setYears] = useState([])
  const [selectedMonth, setSelectedMonth] = useState('')
  const [selectedYear, setSelectedYear] = useState('')

  useEffect(() => {
    if (agreements.length === 0) return
    const allPeriods = _getAllAgreementsPeriods()
    const years = [...new Set(allPeriods.map((period) => yearDate(period)))]
    const yearsList = years.map((year) => {
      return { id: year, value: year }
    })
    setAllPeriods(allPeriods)
    setYears(yearsList)
    setSelectedYear(years[years.length - 1])
  }, [agreements])

  useEffect(() => {
    if (allPeriods.length === 0) return
    const periods = allPeriods
      .filter((period) => yearDate(period) === selectedYear)
      .map((period) => ({ id: monthYearDate(period), value: monthDate(period) }))
    setMonths(periods)
    setSelectedMonth(periods[periods.length - 1].id)
    onChange(periods[periods.length - 1].id)
  }, [selectedYear])

  function _getAllAgreementsPeriods() {
    const periods = []
    agreements.forEach((agreement) => {
      let currentDate = new Date(agreement.periodStart)
      const lastDate = new Date(agreement.periodEnd) > new Date() ? new Date() : new Date(agreement.periodEnd)
      while (currentDate < lastDate) {
        periods.push(currentDate.toString())
        currentDate.setMonth(currentDate.getMonth() + 1)
      }
    })

    return sortDates(periods)
  }

  return (
    <Box display="flex">
      <Box mr={1}>
        <CommonTextField
          name="yearsSelect"
          value={selectedYear}
          onChange={(value) => {
            setSelectedYear(value)
          }}
          disabled={years.length === 0}
          select={years}
        />
      </Box>
      <Box>
        <CommonTextField
          name="monthsSelect"
          value={selectedMonth}
          onChange={(value) => {
            setSelectedMonth(value)
            onChange(value)
          }}
          disabled={months.length === 0}
          select={months}
        />
      </Box>
    </Box>
  )
}
