import { Box, Typography } from '@material-ui/core'
import DownloadIcon from '@material-ui/icons/GetApp'
import PropTypes from 'prop-types'
import createEmptyStatement from '../../../services/excel/createEmptyStatement'
import File from '../../UI/File'
import Subtitle from '../../UI/SubTitle'
import StatementNoAgreementError from '../StatementNoAgreementError'

StatementDownload.propTypes = {
  period: PropTypes.string.isRequired,
  supplier: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
}

export default function StatementDownload({ title, supplier, period }) {
  return (
    <div>
      <Subtitle text={title} />
      {supplier && supplier.agreement?.id ? (
        <Box>
          <Typography variant="body2">
            Exportez votre fichier de collecte vierge pour la période de {period}.
            <br />
            Après l’avoir complété, vous pourrez l’importer dans l’étape suivante.
          </Typography>
          <File
            onClick={() => createEmptyStatement(supplier, period)}
            icon={<DownloadIcon style={{ margin: '20px', fontSize: '80px' }} />}
          >
            <Typography variant="caption">Télécharger mon fichier</Typography>
          </File>
        </Box>
      ) : (
        <Box mt={2}>
          <StatementNoAgreementError />
        </Box>
      )}
    </div>
  )
}
