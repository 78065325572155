import { MsalProvider } from '@azure/msal-react'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import './App.css'
import LoginLayout from './components/Layout/LoginLayout'
import BackOfficeTemplate from './components/Template/BackOfficeTemplate'
import SupplierTemplate from './components/Template/SupplierTemplate'
import routes from './routes'
import { msalInstance } from './services/msal'
import { BO_ROLES, SUPPLIERS_ROLES } from './utils/constants'

require('dotenv').config()

const theme = createTheme({
  palette: {
    primary: {
      main: '#205527',
    },
    secondary: {
      main: '#000',
    },
  },
})

export default function App() {
  return (
    <MsalProvider instance={msalInstance}>
      <Router>
        <ThemeProvider theme={theme}>
          <Switch>
            {routes.map((route) => {
              return route.protected ? (
                route.authRoles === BO_ROLES ? (
                  <Route
                    key={route.path}
                    exact
                    path={route.path}
                    render={(props) => (
                      <BackOfficeTemplate>
                        <route.component {...props} />
                      </BackOfficeTemplate>
                    )}
                  />
                ) : (
                  route.authRoles === SUPPLIERS_ROLES && (
                    <Route
                      key={route.path}
                      exact
                      path={route.path}
                      render={(props) => (
                        <SupplierTemplate>
                          <route.component {...props} />
                        </SupplierTemplate>
                      )}
                    />
                  )
                )
              ) : (
                <Route
                  key={route.path}
                  exact
                  path={route.path}
                  render={(props) => (
                    <LoginLayout key={route.path}>
                      <route.component {...props} />
                    </LoginLayout>
                  )}
                />
              )
            })}
          </Switch>
        </ThemeProvider>
      </Router>
    </MsalProvider>
  )
}
