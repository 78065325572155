import { Box, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'
import GreyCard from '../../UI/GreyCard'

SupplierCard.propTypes = {
  children: PropTypes.element.isRequired,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  subtitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

export default function SupplierCard({ children, content, subtitle, title }) {
  return (
    <Box style={{ marginBottom: '20px' }}>
      <Typography variant="h6" gutterBottom style={{ textTransform: 'uppercase' }}>
        {title}
      </Typography>
      <GreyCard>
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>
            <Box fontWeight="fontWeightBold">{subtitle}</Box>
            <Box>{content}</Box>
          </Box>
          <Box>{children}</Box>
        </Box>
      </GreyCard>
    </Box>
  )
}
