import { Box, Button, CircularProgress, Divider, Grid, Typography } from '@material-ui/core'
import DownloadIcon from '@material-ui/icons/GetApp'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import API from '../../api/api'
import { supplierStatementPeriodByUserQuery } from '../../api/queries/supplierQueries'
import BodyLayout from '../../components/Layout/BodyLayout'
import Alert from '../../components/UI/Alert'
import MainMenu from '../../components/UI/Menu/MainMenu'
import WelcomeBox from '../../components/UI/WelcomeBox'
import { UserContext } from '../../contexts/userContext'
import { useAlert } from '../../hooks/useAlert'
import { useErrorState } from '../../hooks/useErrorState'
import download from '../../services/file/download'
import { FILE_TYPES, PROCESS_STATEMENTS_FILE } from '../../utils/constants'
import { monthYearDate } from '../../utils/format/date'

export default function Home(props) {
  const { alert, setReset, setSuccess } = useAlert()
  const { error, setErrorState } = useErrorState()
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(false)
  const [state, setState] = useState({
    isStatementAvailable: false,
    supplier: {},
  })
  const user = useContext(UserContext)

  useEffect(() => {
    API.queryAPI(supplierStatementPeriodByUserQuery(user.userEmail))
      .then((result) => {
        const isStatementAvailable = _checkAvailableStatement(result.supplierByUser.statements)
        setState({
          isStatementAvailable,
          supplier: result.supplier,
        })
        if (props.location.state?.isStatementSent) {
          setSuccess('Déclaration envoyée')
          history.replace('/') // Empty location state
        }
      })
      .catch((errorMessage) => setErrorState(errorMessage))
  }, [])

  function _checkAvailableStatement(statements) {
    const currentDate = new Date()
    return statements?.some((statement) => {
      const period = new Date(statement.period)
      if (currentDate.getMonth() !== 0)
        return period.getFullYear() === currentDate.getFullYear() && period.getMonth() === currentDate.getMonth() - 1
      else return period.getFullYear() === currentDate.getFullYear() - 1 && period.getMonth() === 11
    })
  }

  async function _handleOnClickMenu(name) {
    setIsLoading(true)
    try {
      if (name === 'process') await download(PROCESS_STATEMENTS_FILE, FILE_TYPES.doc, false)
    } catch {
      setState({ alert: getAlertOpenInfo(false) })
    } finally {
      setIsLoading(false)
    }
  }

  const menu = [
    {
      title: 'Gestion',
      action: [
        {
          text: 'Déclarer mon CA',
          path: 'statement',
        },
        { text: 'Historique', path: 'history' },
        {
          text: 'Mes contrats',
          path: 'contracts',
          data: { supplier: { id: state.supplier?.id, name: state.supplier?.name } },
        },
        {
          text: 'Procédure',
          path: 'process',
          icon: isLoading ? <CircularProgress style={{ height: '20px', width: '20px' }} /> : <DownloadIcon />,
          customAction: true,
        },
      ],
    },
  ]

  return (
    <div>
      <Alert onClose={setReset} open={alert.open} success={alert.success} message={alert.message} />
      {!error.status && (
        <WelcomeBox subtitle="Bienvenue dans le portail de déclaration des CA">
          <Grid container style={{ marginTop: '15px' }}>
            <Grid item sm={3}>
              {!state.isStatementAvailable ? (
                <Box mb={2}>
                  <Button variant="contained" color="primary" onClick={() => history.push('statement')}>
                    Déclarer mon CA
                  </Button>
                  <Box mt={2} mb={1}>
                    <Divider />
                  </Box>
                  <Box>
                    <Typography variant="body2">Déclaration attendue avant le 15 du mois</Typography>
                  </Box>
                </Box>
              ) : (
                <Box>
                  <Box display="flex" mb={1}>
                    <Box fontWeight="fontWeightBold" flexGrow={1} mr={1}>
                      Prochaine déclaration
                    </Box>
                    <Box>
                      <Typography>{monthYearDate()}</Typography>
                    </Box>
                  </Box>
                  <Divider />
                </Box>
              )}
            </Grid>
          </Grid>
        </WelcomeBox>
      )}
      <BodyLayout error={error} menu={<MainMenu items={menu} onClick={_handleOnClickMenu} />}></BodyLayout>
    </div>
  )
}
