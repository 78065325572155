import { Box, Button, Tab, Tabs } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import API from '../../../api/api'
import { getUserByRoleQuery } from '../../../api/queries/userQueries'
import { useAlert } from '../../../hooks/useAlert'
import { ROLES } from '../../../utils/constants'
import { sortObjectsArray } from '../../../utils/functions/common'
import Alert from '../../UI/Alert'
import AddUserDialog from '../AddUserDialog'
import UsersSettingsTable from '../UsersSettingsTable'

UsersSettings.propTypes = {
  onDataLoaded: PropTypes.func.isRequired,
}

const TABS_ID = { external: 0, internal: 1 }

export default function UsersSettings({ onDataLoaded }) {
  const { alert, setSuccess, setError, setReset } = useAlert()
  const [openUserDialog, setOpenUserDialog] = useState(false)
  const [state, setState] = useState({
    internals: [],
    externals: [],
  })
  const [tabValue, setTabValue] = useState(TABS_ID.external)

  useEffect(() => {
    API.queryAPI(
      `{
      BO: ${getUserByRoleQuery(ROLES.BO).slice(1, -1)}
      owners: ${getUserByRoleQuery(ROLES.owner).slice(1, -1)}
      suppliers: ${getUserByRoleQuery(ROLES.supplier).slice(1, -1)}
      rav: ${getUserByRoleQuery(ROLES.RAV).slice(1, -1)}
    }`
    )
      .then((users) => {
        const internals = sortObjectsArray([...users.BO, ...users.owners], 'firstname')
        const externals = sortObjectsArray([...users.suppliers, ...users.rav], 'firstname')
        setState({ internals, externals })
      })
      .catch((errorMessage) => onDataLoaded(errorMessage))
  }, [])

  async function _handleUserDeleted(userDeleted, error, isExternal) {
    if (error.status) {
      setError(error.message)
      return
    }

    const internals = isExternal
      ? state.internals
      : sortObjectsArray(
          state.internals.filter((user) => user.id !== userDeleted.id),
          'firstname'
        )
    const externals = isExternal
      ? sortObjectsArray(
          state.externals.filter((user) => user.id !== userDeleted.id),
          'firstname'
        )
      : state.externals
    setState({
      internals,
      externals,
    })
    setSuccess('Utilisateur supprimé')
  }

  async function _handleUserCreated(newUser, error) {
    if (error.status) {
      setError(error.message)
      return
    }
    const internals = sortObjectsArray([...state.internals, newUser], 'firstname')
    setState({
      ...state,
      internals,
    })
    setSuccess()
  }

  return (
    <>
      <Alert onClose={setReset} open={alert.open} success={alert.success} message={alert.message} />
      <Tabs
        value={tabValue}
        indicatorColor="primary"
        textColor="primary"
        onChange={(_event, newValue) => setTabValue(newValue)}
      >
        <Tab label="Utilisateurs externes" id={TABS_ID.external} />
        <Tab label="Utilisateurs internes" id={TABS_ID.internal} />
      </Tabs>
      {tabValue === TABS_ID.external && (
        <UsersSettingsTable
          data={state.externals}
          onDelete={(userDeleted, error) => _handleUserDeleted(userDeleted, error, true)}
        />
      )}
      {tabValue === TABS_ID.internal && (
        <>
          <Box display="flex" justifyContent="flex-end" mb={2}>
            <Button
              startIcon={<AddIcon />}
              variant="contained"
              color="secondary"
              onClick={() => setOpenUserDialog(true)}
            >
              Ajouter un utilisateur
            </Button>
          </Box>
          <UsersSettingsTable
            data={state.internals}
            displayRole
            onDelete={(userDeleted, error) => _handleUserDeleted(userDeleted, error, false)}
          />
          {openUserDialog && (
            <AddUserDialog
              open={openUserDialog}
              onClose={() => setOpenUserDialog(false)}
              onValidate={(newUser, error) => _handleUserCreated(newUser, error)}
            />
          )}
        </>
      )}
    </>
  )
}
