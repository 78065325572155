import { supplierAgreement } from '../types'

export const updateAgreementFrequencyMutation = (id, frequency) => {
  return `mutation {
    updateAgreementFrequency(id: ${id}, frequency: {
      january: ${frequency.january},
      february: ${frequency.february},
      march: ${frequency.march},
      april: ${frequency.april},
      may: ${frequency.may},
      june: ${frequency.june},
      july: ${frequency.july},
      august: ${frequency.august},
      september: ${frequency.september},
      october: ${frequency.october},
      november: ${frequency.november},
      december: ${frequency.december}
    }) {
      ${supplierAgreement}
    }
  }`
}

export const deleteAgreementMutation = (id) => {
  return `mutation {
      deleteAgreement(id: ${id}) {
      id
    }
  }`
}
