import { useState } from 'react'

export function useErrorState() {
  const [error, setError] = useState({ status: false, message: '' })

  const setErrorState = (message = '') => {
    setError({ status: message !== '', message })
  }

  return { error, setErrorState }
}
