import { monthDate } from '../format/date'
import { sortObjectsArray, sortWithNumbers } from './common'

export const getPeriodsByYears = (newPeriods) => {
  const periods = sortWithNumbers([...newPeriods])
  const years = [...new Set(periods.map((period) => period.split('-')[0]))]
  const periodsByYears = years.map((year) => ({
    id: year,
    name: year,
    data: periods
      .filter((period) => period.split('-')[0] === year)
      .map((periodByYear) => ({ id: periodByYear, name: monthDate(`${periodByYear}-01`) })),
  }))
  return sortObjectsArray(periodsByYears, 'id').reverse()
}

export const getLastPeriodArray = (items) => (items.length > 0 ? [items[0].data[items[0].data?.length - 1].id] : [])

export const getFiles = (supplier) =>
  supplier.statements.map((statement) => ({
    supplierId: supplier.id,
    period: statement.period,
    file: statement.file,
  }))

export const getTurnovers = (supplier) =>
  supplier.statements.map((statement) => ({
    supplierId: supplier.id,
    period: statement.period,
    amount: statement.amounts.length > 0 ? statement.amounts.reduce((total, current) => total + current.amount, 0) : 0,
    discount:
      statement.amounts.length > 0
        ? statement.amounts.reduce(
            (total, current) => total + current.amount * (current.coopGroup.centralTax + current.coopGroup.regionTax),

            0
          )
        : 0,
  }))

export const getTurnoversByCurrentYear = (turnovers, periods) =>
  turnovers.filter((turnover) => periods[0].data.map((period) => period.id).includes(turnover.period))

export const getFiltersByLastPeriod = (lastPeriod, files, turnovers) => {
  const filteredFiles = files.filter((file) => file.period === lastPeriod)
  const filteredTurnovers = turnovers.filter((turnover) => turnover.period === lastPeriod)
  return { filteredFiles, filteredTurnovers }
}
