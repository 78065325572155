import { Box, Divider, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'
import './Title.css'

Title.propTypes = {
  text: PropTypes.string.isRequired,
  variant: PropTypes.string,
  className: PropTypes.string.isRequired,
}

export default function Title({ text, variant, className }) {
  return (
    <Box display="flex" flexDirection="row">
      <Box>
        <Typography variant={variant} style={{ textTransform: 'uppercase' }} className={`title-${className}`}>
          {text}
        </Typography>
      </Box>
      <Box flexGrow={1} alignItems="center">
        <Divider variant="middle" className={`divider-${className}`} />
      </Box>
    </Box>
  )
}
