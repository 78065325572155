import React, { useContext, useEffect, useState } from 'react'
import API from '../../../api/api'
import { getSupplierHistoryByUserQuery } from '../../../api/queries/supplierQueries'
import HistoryMenu from '../../../components/History/HistoryMenu'
import HistoryView from '../../../components/History/HistoryView'
import BodyLayout from '../../../components/Layout/BodyLayout'
import Alert from '../../../components/UI/Alert'
import { UserContext } from '../../../contexts/userContext'
import { useAlert } from '../../../hooks/useAlert'
import { useErrorState } from '../../../hooks/useErrorState'
import createStatementsReport from '../../../services/excel/createStatementsReport'
import {
  getFiles,
  getLastPeriodArray,
  getPeriodsByYears,
  getTurnovers,
  getTurnoversByCurrentYear,
} from '../../../utils/functions/history'

export default function History() {
  const { alert, setError, setReset } = useAlert()
  const { error, setErrorState } = useErrorState()
  const [state, setState] = useState({
    files: [],
    periods: [],
    turnovers: [],
    filteredFiles: [],
    filteredTurnovers: [],
    selectedPeriods: [],
    supplierIdNameAndStatements: {},
  })
  const user = useContext(UserContext)

  useEffect(() => {
    API.queryAPI(getSupplierHistoryByUserQuery(user.userEmail))
      .then(({ supplierByUser }) => {
        const periods = supplierByUser.statements.map((statement) => statement.period)
        const sortedPeriodsByYear = getPeriodsByYears(periods)
        const filesByPeriods = getFiles(supplierByUser)
        const turnoversByPeriods = getTurnovers(supplierByUser)
        const [lastPeriod] = getLastPeriodArray(sortedPeriodsByYear)
        setState({
          files: filesByPeriods,
          periods: sortedPeriodsByYear,
          turnovers: turnoversByPeriods,
          filteredFiles: filesByPeriods.filter((file) => file.period === lastPeriod),
          filteredTurnovers: turnoversByPeriods.filter((turnover) => turnover.period === lastPeriod),
          selectedPeriods: [lastPeriod],
          supplierIdNameAndStatements: {
            id: supplierByUser.id,
            name: supplierByUser.name,
            statements: supplierByUser.statements,
          },
        })
      })
      .catch((errorMessage) => setErrorState(errorMessage))
  }, [])

  async function _handleExport() {
    try {
      createStatementsReport(state.selectedPeriods, [state.supplierIdNameAndStatements])
    } catch {
      setError()
    }
  }

  return (
    <BodyLayout error={error}>
      <>
        <Alert message={alert.message} open={alert.open} success={alert.success} onClose={setReset} />
        <HistoryView
          menu={
            <HistoryMenu
              files={state.files}
              periods={state.periods}
              selectedPeriods={state.selectedPeriods}
              turnovers={state.turnovers}
              onChange={({ filteredFiles, filteredTurnovers, selectedPeriods }) =>
                setState({ ...state, filteredFiles, filteredTurnovers, selectedPeriods })
              }
              onDownload={() => _handleExport()}
            />
          }
          files={state.filteredFiles}
          selectedTurnovers={state.filteredTurnovers}
          turnovers={getTurnoversByCurrentYear(state.turnovers, state.periods)}
        />
      </>
    </BodyLayout>
  )
}
