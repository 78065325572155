import React, { useContext, useEffect, useState } from 'react'
import API from '../../../api/api'
import { supplierByUserQuery } from '../../../api/queries/supplierQueries'
import ContractsView from '../../../components/Contracts/ContratsView'
import BodyLayout from '../../../components/Layout/BodyLayout'
import NavDetails from '../../../components/UI/NavDetails'
import { UserContext } from '../../../contexts/userContext'
import { useErrorState } from '../../../hooks/useErrorState'

export default function Contracts() {
  const { error, setErrorState } = useErrorState()
  const [supplier, setSupplier] = useState({})
  const user = useContext(UserContext)

  useEffect(() => {
    API.queryAPI(supplierByUserQuery(user.userEmail))
      .then((result) => setSupplier(result.supplierByUser))
      .catch((errorMessage) => setErrorState(errorMessage))
  }, [])

  return (
    <BodyLayout error={error}>
      <>
        <NavDetails name="Mes contrats" />
        <ContractsView supplier={supplier} onDataLoaded={(errorMessage) => setErrorState(errorMessage)} />
      </>
    </BodyLayout>
  )
}
