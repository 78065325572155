export const formatDate = (value) => {
  const date = new Date(value)
  return date.toLocaleDateString('fr-FR', { year: 'numeric', month: '2-digit', day: '2-digit' })
}

export const today = () => {
  return new Date().toISOString().slice(0, 10)
}

export const monthYearDate = (value = null) => {
  const date = value !== null ? new Date(value) : new Date()
  return date.toLocaleDateString('fr-FR', { month: 'long', year: 'numeric' })
}

export const monthYearDateFromFile = (value = null) => {
  const date = value !== null ? new Date(`${value.substring(0, 4)}${value.substring(4)}`) : new Date()
  return date.toLocaleDateString('fr-FR', { month: 'long', year: 'numeric' })
}

export const yearDate = (value = null) => {
  const date = value !== null ? new Date(value) : new Date()
  return date.toLocaleDateString('fr-FR', { year: 'numeric' })
}

export const monthDate = (value = null) => {
  const date = value !== null ? new Date(value) : new Date()
  return date.toLocaleDateString('fr-FR', { month: 'long' })
}

export const monthNumber = (month) => {
  return (
    [
      'janvier',
      'février',
      'mars',
      'avril',
      'mai',
      'juin',
      'juillet',
      'août',
      'septembre',
      'octobre',
      'novembre',
      'décembre',
    ].indexOf(month) + 1
  )
}
