import { BlobServiceClient } from '@azure/storage-blob'
import API from '../../api/api'
import { getSasQuery } from '../../api/queries/storageQueries'

export default async function (file, container, supplierName) {
  if (!file) return

  try {
    const containerByEnv = `${container}-${process.env.REACT_APP_ENV}`
    const sasToken = await API.queryAPI(getSasQuery(containerByEnv))
    const blobService = new BlobServiceClient(`https://portalfb.blob.core.windows.net/?${sasToken.getStorageKey.key}`)
    const containerClient = blobService.getContainerClient(containerByEnv)

    const date = new Date()
    const fileExt = file.name.substring(file.name.lastIndexOf('.') + 1)
    const filePath = `${supplierName}/${date.getFullYear()}${
      date.getMonth() + 1
    }${date.getDate()}${date.getHours()}${date.getMinutes()}${date.getSeconds()}.${fileExt}`
    const blobClient = containerClient.getBlockBlobClient(filePath)
    const options = { blobHTTPHeaders: { blobContentType: fileExt } }

    const uploaded = await blobClient.uploadData(file, options)
    if (uploaded._response.status !== 201) throw uploaded._response.status

    return filePath
  } catch (e) {
    return e
  }
}
