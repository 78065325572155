import { REGIONS } from '../../utils/constants'
import { setBackGroundColor, setBold, setBorder, setCellNumberFormat } from './common'

const importantColor = 'FAC090'
const rateColor = 'FFC000'
const contentColor = 'FFFF99'
const cells = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
  'AA',
  'AB',
  'AC',
  'AD',
  'AE',
  'AF',
  'AG',
  'AH',
  'AI',
  'AJ',
  'AK',
  'AL',
  'AM',
  'AN',
  'AO',
  'AP',
]
const rateCells = ['B', 'C', 'D']
const totalCells = ['M', 'X', 'AG', 'AP']

export const setColumnsWidth = (worksheet) =>
  (worksheet.columns = cells.map((cell) => (cell === 'A' || cell === 'O' ? { width: 60 } : { width: 12 })))

export const addHeaderRow = (worksheet, rowNumber, cellsValues, cellsLetters) => {
  worksheet.addRow(cells.map((cell) => cellsValues[cell] ?? ''))
  setBold(worksheet, rowNumber, true)
  worksheet.getRow(rowNumber).alignment = { vertical: 'middle', horizontal: 'center' }
  worksheet.mergeCells(`${cellsLetters[0]}${rowNumber}:${cellsLetters[1]}${rowNumber}`)
  worksheet.mergeCells(`${cellsLetters[2]}${rowNumber}:${cellsLetters[3]}${rowNumber}`)
  worksheet.mergeCells(`${cellsLetters[4]}${rowNumber}:${cellsLetters[5]}${rowNumber}`)
  worksheet.mergeCells(`${cellsLetters[6]}${rowNumber}:${cellsLetters[7]}${rowNumber}`)
}

export const addTableHeader = (worksheet, row) => {
  worksheet.addRow([
    'Accords = Gamme',
    'Tx Coop filiales',
    'Tx Coop SAS',
    'Total',
    REGIONS[0].code,
    REGIONS[1].code,
    REGIONS[2].code,
    REGIONS[3].code,
    REGIONS[4].code,
    REGIONS[5].code,
    REGIONS[6].code,
    REGIONS[7].code,
    'Total général',
    '',
    'Accords = Gamme',
    REGIONS[0].code,
    REGIONS[1].code,
    REGIONS[2].code,
    REGIONS[3].code,
    REGIONS[4].code,
    REGIONS[5].code,
    REGIONS[6].code,
    REGIONS[7].code,
    'Total filiales',
    REGIONS[0].code,
    REGIONS[1].code,
    REGIONS[2].code,
    REGIONS[3].code,
    REGIONS[4].code,
    REGIONS[5].code,
    REGIONS[6].code,
    REGIONS[7].code,
    'Total SAS',
    REGIONS[0].code,
    REGIONS[1].code,
    REGIONS[2].code,
    REGIONS[3].code,
    REGIONS[4].code,
    REGIONS[5].code,
    REGIONS[6].code,
    REGIONS[7].code,
    'Total',
  ])

  // Add style to the new row
  setBold(worksheet, row)
  worksheet.getRow(row).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true }

  // Set background color, border and format to the new row
  cells.forEach((cell) => {
    if (cell === 'N') return
    setBorder(worksheet, `${cell}${row}`)
    if (rateCells.includes(cell)) setBackGroundColor(worksheet, `${cell}${row}`, rateColor)
    else setBackGroundColor(worksheet, `${cell}${row}`, importantColor)
  })
}

export const addTableRow = (worksheet, coopGroup, amounts, row) => {
  worksheet.addRow([
    coopGroup.name,
    coopGroup.regionTax,
    coopGroup.centralTax,
    coopGroup.regionTax + coopGroup.centralTax,
    ...amounts,
    { formula: `SUM(E${row}:L${row})` },
    ,
    coopGroup.name,
    { formula: `E${row}*$B${row}` },
    { formula: `F${row}*$B${row}` },
    { formula: `G${row}*$B${row}` },
    { formula: `H${row}*$B${row}` },
    { formula: `I${row}*$B${row}` },
    { formula: `J${row}*$B${row}` },
    { formula: `K${row}*$B${row}` },
    { formula: `L${row}*$B${row}` },
    { formula: `SUM(P${row}:W${row})` },
    { formula: `E${row}*$C${row}` },
    { formula: `F${row}*$C${row}` },
    { formula: `G${row}*$C${row}` },
    { formula: `H${row}*$C${row}` },
    { formula: `I${row}*$C${row}` },
    { formula: `J${row}*$C${row}` },
    { formula: `K${row}*$C${row}` },
    { formula: `L${row}*$C${row}` },
    { formula: `SUM(Y${row}:AF${row})` },
    { formula: `P${row}+Y${row}` },
    { formula: `Q${row}+Z${row}` },
    { formula: `R${row}+AA${row}` },
    { formula: `S${row}+AB${row}` },
    { formula: `T${row}+AC${row}` },
    { formula: `U${row}+AD${row}` },
    { formula: `V${row}+AE${row}` },
    { formula: `W${row}+AF${row}` },
    { formula: `SUM(AH${row}:AO${row})` },
  ])

  // Set background color, border and format to the new row
  cells.forEach((cell) => {
    if (cell === 'N') return

    setBorder(worksheet, `${cell}${row}`)
    if (['A', 'O'].includes(cell)) return

    const currentCell = worksheet.getCell(`${cell}${row}`)
    if (rateCells.includes(cell)) coopGroup.isPercent ? (currentCell.numFmt = '0.00%') : (currentCell.numFmt = '0.00€')
    else setCellNumberFormat(worksheet, cell, row)

    const backgroundColor = rateCells.includes(cell) || totalCells.includes(cell) ? rateColor : contentColor
    setBackGroundColor(worksheet, `${cell}${row}`, backgroundColor)
  })
}

export const addTableTotalRow = (worksheet, startingRow, row) => {
  worksheet.addRow(
    cells.map((cell) => {
      if (cell === 'A') return 'Total'
      else if (['B', 'C', 'D', 'N', 'O'].includes(cell)) return ''
      else if (cell === 'M') return { formula: `SUM(E${startingRow + 2}:L${row - 1})` }
      else return { formula: `SUM(${cell}${startingRow + 2}:${cell}${row - 1})` }
    })
  )

  // Set background color, border and format to the new row
  setBold(worksheet, row)
  cells.forEach((cell) => {
    if (cell === 'N') return
    setBorder(worksheet, `${cell}${row}`)
    if (rateCells.includes(cell)) setBackGroundColor(worksheet, `${cell}${row}`, rateColor)
    else {
      setBackGroundColor(worksheet, `${cell}${row}`, importantColor)
      setCellNumberFormat(worksheet, cell, row)
    }
  })
}
