import { REGIONS } from '../../utils/constants'
import { getExcelWorksheet } from './common'

export const getPeriodFromFile = (worksheet) => worksheet.getCell(`A1`).value

export function checkFileFormat(filename) {
  return new Promise((resolve) => {
    if (filename.slice(-5) === '.xlsx') resolve(true)
    resolve(false)
  })
}

function checkRegions(worksheet) {
  if (!worksheet.getCell('E2').value.includes(REGIONS[0].code)) return false
  if (worksheet.getCell('F2').value !== REGIONS[1].code) return false
  if (worksheet.getCell('G2').value !== REGIONS[2].code) return false
  if (worksheet.getCell('H2').value !== REGIONS[3].code) return false
  if (worksheet.getCell('I2').value !== REGIONS[4].code) return false
  if (!worksheet.getCell('J2').value.includes(REGIONS[5].code)) return false
  if (worksheet.getCell('K2').value !== REGIONS[6].code) return false
  if (worksheet.getCell('L2').value !== REGIONS[7].code) return false
  return true
}
function checkDate(date) {
  if (typeof date !== 'string') return false
  if (date.split(' ').length !== 2) return false
  return true
}
export async function checkData(file) {
  const worksheet = await getExcelWorksheet(file)
  return new Promise(async (resolve) => {
    if (!checkRegions(worksheet)) resolve(false)
    if (!checkDate(getPeriodFromFile(worksheet))) resolve(false)
    resolve(true)
  })
}

function getCoopGroupsFromFile(worksheet, length) {
  const names = []
  for (let i = 3; i < length + 3; i++) {
    names.push(worksheet.getCell(`A${i}`).value)
  }
  return names
}
export function checkCoopGroups(file, lastAgreement) {
  return new Promise(async (resolve) => {
    const worksheet = await getExcelWorksheet(file)

    const coopGroupsLength = lastAgreement.coopGroups.length
    if (worksheet.getCell(`A${coopGroupsLength + 3}`).value !== 'Total') resolve(false)

    const coopGroupsName = lastAgreement.coopGroups.map((group) => group.name)
    const coopGroupsNameFromFile = getCoopGroupsFromFile(worksheet, coopGroupsLength)

    if (coopGroupsLength !== coopGroupsNameFromFile.length) resolve(false)
    if (!coopGroupsName.every((name) => coopGroupsNameFromFile.includes(name))) resolve(false)

    resolve(true)
  })
}

export function checkAmount(file, coopGroups) {
  return new Promise(async (resolve) => {
    const worksheet = await getExcelWorksheet(file)

    const cellsToCheck = []
    coopGroups.forEach((_group, index) => {
      cellsToCheck.push(
        `E${index + 3}`,
        `F${index + 3}`,
        `G${index + 3}`,
        `H${index + 3}`,
        `I${index + 3}`,
        `J${index + 3}`,
        `K${index + 3}`,
        `L${index + 3}`
      )
    })

    for (const cell of cellsToCheck) {
      const cellValue = worksheet.getCell(cell).value
      if (String(cellValue).replaceAll(' ', '') === '' || cellValue === null) resolve(false)
    }

    resolve(true)
  })
}
