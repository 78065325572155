import { getExcelWorksheet } from './common'

export async function sumStatement(file, agreement) {
  const worksheet = await getExcelWorksheet(file)
  return {
    turnover: worksheet.getCell(`M${agreement.coopGroups.length + 3}`).value.result ?? 0,
    totalDiscount: worksheet.getCell(`AP${agreement.coopGroups.length + 3}`).value.result ?? 0,
    natDiscount: worksheet.getCell(`AG${agreement.coopGroups.length + 3}`).value.result ?? 0,
    localDiscount: worksheet.getCell(`X${agreement.coopGroups.length + 3}`).value.result ?? 0,
  }
}
