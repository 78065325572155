import { Box } from '@material-ui/core'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { upperFirstLetter } from '../../../utils/format/text'
import { sortObjectsArray } from '../../../utils/functions/common'
import CommonTextField from '../../UI/Form/CommonTextField'

UsersSelect.propTypes = {
  all: PropTypes.bool,
  defaultUser: PropTypes.number,
  items: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
  none: PropTypes.bool,
  onUserChange: PropTypes.func.isRequired,
}
UsersSelect.defaultProps = {
  all: false,
  defaultUser: 0,
  none: false,
}

const initialUsers = [{ id: 0, value: 'Aucun' }]
export default function UsersSelect({ all, defaultUser, items, label, none, onUserChange }) {
  const [users, setUsers] = useState(initialUsers)
  const [currentUser, setCurrentUser] = useState(0)

  useEffect(() => {
    setUsers((prevUsers) => {
      if (!all) return
      prevUsers[0] = { id: 0, value: 'Tous' }
      return prevUsers
    })
  }, [all])

  useEffect(() => {
    setUsers((prevUsers) => {
      if (!prevUsers) return initialUsers
      const newUsers = items.map((item) => {
        return {
          id: item.id,
          value: `${upperFirstLetter(item.firstname)} ${upperFirstLetter(item.lastname)}`,
        }
      })
      const sortedUsers = sortObjectsArray([...new Set(newUsers)], 'value')
      return all || none ? [prevUsers[0], ...sortedUsers] : sortedUsers
    })
  }, [items])

  useEffect(() => {
    setCurrentUser(defaultUser)
  }, [defaultUser])

  return (
    <Box mb={2}>
      <CommonTextField
        name="usersSelect"
        label={label}
        value={currentUser}
        onChange={(value) => {
          setCurrentUser(value)
          onUserChange(value)
        }}
        select={users}
      />
    </Box>
  )
}
