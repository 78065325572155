export const getSuppliersByOwner = (ownerId, suppliers) => {
  return ownerId === 0 ? suppliers : suppliers.filter((supplier) => supplier.owner.id === ownerId)
}

export const sortObjectsArray = (array, propName) =>
  array.sort((a, b) => a[propName].localeCompare(b[propName], undefined, { numeric: true, sensitivity: 'base' }))

export const sortDates = (array) =>
  array.sort((a, b) => {
    const date1 = new Date(a)
    const date2 = new Date(b)
    return date1.getTime() - date2.getTime()
  })

export const sortWithNumbers = (array) =>
  array.sort((a, b) => a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' }))
