import { Box, IconButton, Link } from '@material-ui/core'
import { DataGrid, frFR } from '@material-ui/data-grid'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import CloseIcon from '@material-ui/icons/Close'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import downloadFile from '../../../services/file/download'
import { CONTRACT_STATUS, FILE_TYPES, REGIONS } from '../../../utils/constants'
import { formatDate } from '../../../utils/format/date'
import './ContractsTable.css'

ContractsTable.propTypes = {
  contracts: PropTypes.array.isRequired,
  validation: PropTypes.bool.isRequired,
  onOpenValidation: PropTypes.func,
  onOpenRefusal: PropTypes.func,
  onSelected: PropTypes.func,
}

export default function ContractsTable({ contracts, validation, onOpenValidation, onOpenRefusal, onSelected }) {
  // Column field's value needs to be the same as the row prop, to order with this value
  const commonColumns = [
    { field: 'contractName', headerName: 'Contrat', width: 200, renderCell: renderContractCell },
    { field: 'region', headerName: 'Région', flex: 1 },
    { field: 'businessUnitCode', headerName: 'Site FB (BU)', width: 230 },
    { field: 'salesPointCode', headerName: 'Code client FB', flex: 1 },
    { field: 'startDate', headerName: 'Date début', flex: 1 },
    { field: 'endDate', headerName: 'Date fin', flex: 1 },
    { field: 'statusName', headerName: 'Statut', flex: 1, renderCell: renderStatusCell },
    { field: 'volume', headerName: 'Volume prévu', type: 'number', flex: 0.8, renderCell: renderVolumeCell },
    { field: 'file', headerName: ' ', align: 'center', flex: 1, renderCell: renderContractLink },
  ]
  const validationColumn = {
    field: 'validation',
    headerName: ' ',
    align: 'center',
    flex: 1,
    renderCell: renderValidation,
  }
  const [columns, setColumns] = useState(commonColumns)
  const rows = contracts.map((contract) => {
    return {
      id: contract.id,
      contractName: contract.salesPoint.name,
      region: REGIONS.find((region) => region.id === contract.salesPoint.businessUnit.region).name,
      contractCity: contract.salesPoint.address.city,
      businessUnitCode: `${contract.salesPoint.businessUnit.code} - ${contract.salesPoint.businessUnit.name}`,
      salesPointCode: contract.salesPoint.code,
      startDate: formatDate(contract.startDate),
      endDate: formatDate(contract.endDate),
      statusName: contract.status.name,
      status: contract.status,
      volume: contract.expectedVolume,
      hectoliters: contract.hectoliters,
      file: contract.file,
      contract: contract,
    }
  })

  useEffect(() => {
    if (validation) setColumns([...commonColumns, validationColumn])
  }, [])

  function renderContractCell(params) {
    return (
      <div>
        <Box>{params.row.contractName}</Box>
        <Box color="text.secondary">{params.row.contractCity}</Box>
      </div>
    )
  }

  function renderVolumeCell(params) {
    return (
      <div>
        <Box>{params.row.volume}</Box>
        <Box color="text.secondary">{params.row.hectoliters ? 'HL' : 'BOUTEILLES'}</Box>
      </div>
    )
  }

  function renderContractLink(params) {
    if (params.row.file !== null) {
      return (
        <Link
          component="button"
          className="contract-link"
          variant="body2"
          onClick={() => downloadFile(params.row.file.url, FILE_TYPES.contract)}
        >
          Voir contrat
        </Link>
      )
    }
  }

  function renderStatusCell(params) {
    const style = Object.values(CONTRACT_STATUS).find((state) => state.id === params.row.status.id)
    return (
      <div>
        <Box
          className="statusCapsule"
          style={{ backgroundColor: style?.capsuleColor ?? 'var(--green)', color: style?.color ?? 'white' }}
        >
          <span className="statusCapsuleDot" style={{ backgroundColor: style?.color ?? 'var(--green)' }}></span>
          {params.row.status.name}
        </Box>
        <Box className="statusDate" color="text.secondary">
          Depuis le {params.row.status.date}
        </Box>
      </div>
    )
  }

  function renderValidation(params) {
    if (params.row.status.id === CONTRACT_STATUS.pending.id) {
      return (
        <Box display="flex">
          <IconButton aria-label="valider" color="inherit" onClick={() => onOpenValidation(params.row.contract)}>
            <CheckBoxIcon color="primary" />
          </IconButton>
          <IconButton aria-label="refuser" onClick={() => onOpenRefusal(params.row.contract)}>
            <CloseIcon color="secondary" />
          </IconButton>
        </Box>
      )
    }
  }

  return (
    <div style={{ height: 400, width: '100%' }}>
      <DataGrid
        localeText={frFR.props.MuiDataGrid.localeText}
        columns={columns}
        rows={rows}
        checkboxSelection
        disableSelectionOnClick
        onSelectionModelChange={onSelected}
      />
    </div>
  )
}
