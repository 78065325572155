import { Box, IconButton } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import API from '../../../api/api'
import { updateSupplierOwnerMutation } from '../../../api/mutations/supplierMutations'
import { getUserByRoleQuery } from '../../../api/queries/userQueries'
import { useAlert } from '../../../hooks/useAlert'
import { ROLES } from '../../../utils/constants'
import Alert from '../../UI/Alert'
import UsersSelect from '../../UI/UsersSelect'

SupplierOwner.propTypes = {
  ownerId: PropTypes.number.isRequired,
  supplierId: PropTypes.number.isRequired,
  onOwnerUpdated: PropTypes.func.isRequired,
}

export default function SupplierOwner({ ownerId, supplierId, onOwnerUpdated }) {
  const [owners, setOwners] = useState([])
  const [ownerSelected, setOwnerSelected] = useState(ownerId)
  const [isUpdating, setIsUpdating] = useState(false)
  const { alert, setError, setReset, setSuccess } = useAlert()

  useEffect(() => {
    API.queryAPI(getUserByRoleQuery(ROLES.owner)).then((result) => setOwners(result.userByRole))
  }, [])

  function _handleOwnerChange(newOwnerId) {
    setOwnerSelected(newOwnerId)
    setIsUpdating(true)
  }

  function _handleOwnerUpdate() {
    API.queryAPI(updateSupplierOwnerMutation(supplierId, ownerSelected))
      .then((result) => {
        setSuccess()
        onOwnerUpdated(result.updateSupplierOwner)
      })
      .catch(() => setError())
      .finally(() => setIsUpdating(false))
  }

  function _handleOwnerCancel() {
    setOwnerSelected(ownerId)
    setIsUpdating(false)
  }

  return (
    <Box display="flex">
      <Alert onClose={setReset} open={alert.open} success={alert.success} message={alert.message} />
      <UsersSelect
        defaultUser={ownerSelected}
        items={owners}
        label="Modifier l'acheteur"
        onUserChange={(ownerId) => _handleOwnerChange(ownerId)}
      />
      {isUpdating && (
        <Box mt={3}>
          <IconButton onClick={_handleOwnerUpdate}>
            <CheckIcon color="primary" />
          </IconButton>
          <IconButton onClick={_handleOwnerCancel}>
            <CloseIcon color="error" />
          </IconButton>
        </Box>
      )}
    </Box>
  )
}
