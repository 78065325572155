import { Button } from '@material-ui/core'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import API from '../../../api/api'
import { getUserByRoleQuery } from '../../../api/queries/userQueries'
import { ROLES } from '../../../utils/constants'
import { getFiltersByLastPeriod, getLastPeriodArray } from '../../../utils/functions/history'
import CommonTextField from '../../UI/Form/CommonTextField'
import CategorizedSubMenu from '../../UI/Menu/CatogorizedSubMenu'
import UsersSelect from '../../UI/UsersSelect'
import { getFiltersByMultipleSuppliers, getFiltersBySupplier } from './functions'

HistoryMenu.propTypes = {
  displayFilters: PropTypes.bool,
  files: PropTypes.array.isRequired,
  periods: PropTypes.array.isRequired,
  selectedPeriods: PropTypes.array,
  suppliers: PropTypes.array,
  turnovers: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  onDownload: PropTypes.func.isRequired,
}
HistoryMenu.defaultProps = {
  displayFilters: false,
}

export default function HistoryMenu({
  displayFilters,
  files,
  periods,
  selectedPeriods,
  suppliers,
  turnovers,
  onChange,
  onDownload,
}) {
  const [owners, setOwners] = useState([])
  const [state, setState] = useState({
    filteredPeriods: [],
    filteredSuppliers: [],
    selectedOwner: '',
    selectedSupplier: '',
  })

  useEffect(() => {
    if (displayFilters) API.queryAPI(getUserByRoleQuery(ROLES.owner)).then((result) => setOwners(result.userByRole))
  }, [])

  useEffect(() => {
    setState({
      filteredPeriods: periods,
      filteredSuppliers: suppliers,
      selectedOwner: 0,
      selectedSupplier: suppliers?.length > 0 ? 0 : '',
    })
  }, [periods, suppliers])

  const _handleOwnerFilter = (ownerId) => {
    if (ownerId === 0) {
      const [lastPeriod] = getLastPeriodArray(periods)
      const { filteredFiles, filteredTurnovers } = getFiltersByLastPeriod(lastPeriod, files, turnovers)
      setState({
        filteredPeriods: periods,
        filteredSuppliers: suppliers,
        selectedOwner: ownerId,
        selectedSupplier: 0,
      })
      onChange({
        currentSuppliersId: suppliers.map((supplier) => supplier.id),
        filteredFiles,
        filteredTurnovers,
        selectedPeriods: [lastPeriod],
      })
      return
    }

    const filteredSuppliersByOwner = suppliers.filter((supplier) => supplier.ownerId === ownerId)
    const suppliersId = filteredSuppliersByOwner.map((supplier) => supplier.id)
    const { filteredFiles, filteredPeriods, filteredTurnovers } = getFiltersByMultipleSuppliers(
      files,
      suppliers,
      suppliersId,
      turnovers
    )
    const lastPeriod = getLastPeriodArray(filteredPeriods)
    setState({
      ...state,
      filteredPeriods,
      filteredSuppliers: [suppliers[0], ...filteredSuppliersByOwner],
      selectedOwner: ownerId,
      selectedSupplier: 0,
    })
    onChange({
      currentSuppliersId: filteredSuppliersByOwner.map((supplier) => supplier.id),
      filteredFiles,
      filteredTurnovers,
      selectedPeriods: lastPeriod,
    })
  }

  const _handleSupplierFilter = (supplierId) => {
    if (supplierId === 0) {
      const suppliersId = state.filteredSuppliers.map((supplier) => supplier.id)
      suppliersId.shift() // remove id 0
      const { filteredFiles, filteredPeriods, filteredTurnovers } = getFiltersByMultipleSuppliers(
        files,
        suppliers,
        suppliersId,
        turnovers
      )
      const lastPeriod = getLastPeriodArray(filteredPeriods)
      setState({
        ...state,
        filteredPeriods: filteredPeriods,
        selectedSupplier: supplierId,
      })
      onChange({
        currentSuppliersId: suppliersId,
        filteredFiles,
        filteredTurnovers,
        selectedPeriods: lastPeriod,
      })
      return
    }

    const { filteredFiles, filteredPeriods, filteredTurnovers } = getFiltersBySupplier(
      files,
      periods,
      suppliers,
      supplierId,
      turnovers
    )
    const lastPeriod = getLastPeriodArray(filteredPeriods)
    setState({
      ...state,
      filteredPeriods,
      selectedSupplier: supplierId,
    })
    onChange({
      currentSuppliersId: [supplierId],
      filteredFiles,
      filteredTurnovers,
      selectedPeriods: lastPeriod,
    })
  }

  const _handlePeriodFilter = (newPeriods) => {
    const suppliersId = state.selectedSupplier
      ? [state.selectedSupplier]
      : state.filteredSuppliers?.map((supplier) => supplier.id)
    if (!state.selectedSupplier) suppliersId?.shift()
    const filteredFiles = displayFilters
      ? files.filter((file) => suppliersId.includes(file.supplierId) && newPeriods.includes(file.period))
      : files.filter((file) => newPeriods.includes(file.period))
    const filteredTurnovers = displayFilters
      ? turnovers.filter(
          (turnover) => suppliersId.includes(turnover.supplierId) && newPeriods.includes(turnover.period)
        )
      : turnovers.filter((turnover) => newPeriods.includes(turnover.period))
    onChange({
      currentSuppliersId: suppliersId,
      filteredFiles,
      filteredTurnovers,
      selectedPeriods: [...new Set(newPeriods)],
    })
  }

  return (
    <>
      {displayFilters && (
        <>
          <UsersSelect all items={owners} label="Acheteurs" onUserChange={(ownerId) => _handleOwnerFilter(ownerId)} />
          <CommonTextField
            name="suppliersHistorySelect"
            label="Fournisseurs"
            value={state.selectedSupplier}
            onChange={(value) => _handleSupplierFilter(value)}
            select={state.filteredSuppliers}
            disabled={state.filteredSuppliers?.length === 0}
          />
        </>
      )}
      <CategorizedSubMenu
        items={state.filteredPeriods}
        selectedItems={selectedPeriods}
        title="Periodes"
        onChange={(newPeriods) => _handlePeriodFilter(newPeriods)}
      />

      {selectedPeriods.length > 0 && (
        <Button
          fullWidth
          style={{ marginTop: '50px' }}
          color="secondary"
          variant="contained"
          onClick={() =>
            onDownload(
              state.selectedSupplier
                ? [state.selectedSupplier]
                : state.selectedOwner
                ? state.filteredSuppliers.map((supplier) => supplier.id).slice(1) // remove 'all' item from the list
                : []
            )
          }
        >
          Extraire les données
        </Button>
      )}
    </>
  )
}
