import { Box, IconButton, Table, TableBody, TableCell, TableContainer, TableRow, TextField } from '@material-ui/core'
import CreateIcon from '@material-ui/icons/Create'
import Autocomplete from '@material-ui/lab/Autocomplete'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import LoadingTable from '../../UI/LoadingTable'
import Title from '../../UI/Title'

SuppliersSettingsTable.propTypes = {
  suppliers: PropTypes.array.isRequired,
  onEditClick: PropTypes.func.isRequired,
}

export default function SuppliersSettingsTable({ suppliers, onEditClick }) {
  const [displayedSuppliers, setDisplayedSuppliers] = useState([])
  useEffect(() => {
    setDisplayedSuppliers(suppliers)
  }, [suppliers])

  function getNbUser(value) {
    let nbUser = 0
    if (value !== null) nbUser += 1
    return nbUser
  }

  function getStatementUserNumber(supplier) {
    const nbUsers = getNbUser(supplier.contact) + getNbUser(supplier.backupContact)
    return `${nbUsers} utilisateurs pour les déclarations de CA`
  }

  function getUsersByRegionNumber(regions) {
    let nbUsers = 0
    let users = []
    regions.forEach((region) => {
      if (region.contact !== null && !users.some((user) => user.id === region.contact.id)) {
        users.push(region.contact)
        nbUsers += getNbUser(region.contact)
      }
      if (region.backupContact !== null && !users.some((user) => user.id === region.backupContact.id)) {
        users.push(region.backupContact)
        nbUsers += getNbUser(region.backupContact)
      }
    })
    return `${nbUsers} utilisateurs pour les contrats tri partite`
  }

  return (
    <>
      <Autocomplete
        id="search-contact"
        options={suppliers}
        getOptionLabel={(option) => `${option.name}`}
        onChange={(_event, newSupplier) => setDisplayedSuppliers(newSupplier === null ? suppliers : [newSupplier])}
        style={{ width: 300 }}
        renderInput={(params) => <TextField {...params} label="Rechercher" variant="outlined" />}
      />
      <Box pt={4}>
        <Title text="Résultats de la recherche" className="menu" />
      </Box>
      {displayedSuppliers.length === 0 ? (
        <LoadingTable />
      ) : (
        <TableContainer>
          <Table>
            <TableBody>
              {displayedSuppliers?.map((supplier, index) => (
                <TableRow key={index}>
                  <TableCell>{supplier.name} </TableCell>
                  <TableCell>{getStatementUserNumber(supplier)}</TableCell>
                  <TableCell>{getUsersByRegionNumber(supplier.regionUsers)}</TableCell>
                  <TableCell>
                    <IconButton
                      aria-label="modifier"
                      color="secondary"
                      onClick={() => onEditClick(supplier)}
                      size="small"
                    >
                      <CreateIcon fontSize="inherit" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  )
}
