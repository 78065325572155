import { Box } from '@material-ui/core'
import React, { useState } from 'react'
import BodyLayout from '../../../components/Layout/BodyLayout'
import SuppliersSettings from '../../../components/Settings/SuppliersSettings'
import UsersSettings from '../../../components/Settings/UsersSettings/index.jsx'
import SubMenu from '../../../components/UI/Menu/SubMenu'
import NavDetails from '../../../components/UI/NavDetails'
import { useErrorState } from '../../../hooks/useErrorState'

const VIEWS = { default: 0, usersSettingsView: 1, suppliersSettings: 2, statementsSettings: 3 }
const MENU = [
  { id: 1, name: 'Gestion des comptes' },
  { id: 2, name: 'Gestion des fournisseurs' },
]

export default function Settings() {
  const [currentView, setCurrentView] = useState(VIEWS.default)
  const { error, setErrorState } = useErrorState()
  const handleDataLoaded = (errorMessage) => setErrorState(errorMessage)

  return (
    <BodyLayout
      error={error}
      navDetails={<NavDetails name="Paramétrages" />}
      menu={
        <Box>
          <SubMenu
            title="Paramétrages"
            items={MENU}
            currentItem={{ id: currentView }}
            onClick={(view) => setCurrentView(view.id)}
          />
        </Box>
      }
    >
      <>
        {currentView === VIEWS.usersSettingsView ? (
          <UsersSettings onDataLoaded={handleDataLoaded} />
        ) : (
          currentView === VIEWS.suppliersSettings && <SuppliersSettings onDataLoaded={handleDataLoaded} />
        )}
      </>
    </BodyLayout>
  )
}
