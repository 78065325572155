import { Box, Button, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { ReactComponent as FBLogo } from '../../logo-FB.svg'
import LoginLayout from '../Layout/LoginLayout'
import ErrorMessage from '../UI/ErrorMessage'
import CommonTextField from '../UI/Form/CommonTextField'

LoginUser.propTypes = {
  loginWithSSO: PropTypes.bool.isRequired,
  loginError: PropTypes.object,
  onSignIn: PropTypes.func.isRequired,
}

export default function LoginUser({ loginWithSSO, loginError, onSignIn }) {
  const [login, setLogin] = useState('')
  const [password, setPassword] = useState('')
  const history = useHistory()

  return (
    <LoginLayout>
      <>
        <Typography variant="h4" className="loginTitle">
          Bienvenue sur le portail
        </Typography>
        <FBLogo />
        <Box className="loginForm">
          {!loginWithSSO && (
            <>
              <CommonTextField
                name="login"
                value={login}
                onChange={(value) => setLogin(value.trim().toLowerCase())}
                label="Identifiant"
                required
              />
              <CommonTextField
                name="password"
                value={password}
                onChange={(value) => setPassword(value)}
                label="Mot de passe"
                type="password"
                required
              />
              <ErrorMessage error={loginError} />
            </>
          )}
          <Box display="flex" mt={1}>
            <Button onClick={() => onSignIn(login, password)} color="secondary" variant="contained" fullWidth>
              Se connecter
            </Button>
            {!loginWithSSO && (
              <Button
                style={{ marginLeft: '20px' }}
                variant="contained"
                onClick={() => history.push('/resetPassword')}
                fullWidth
              >
                Mot de passe oublié ?
              </Button>
            )}
          </Box>
        </Box>
      </>
    </LoginLayout>
  )
}
