import { Box } from '@material-ui/core'
import React, { useContext, useEffect, useState } from 'react'
import API from '../../../api/api'
import { supplierAgreementByUserQuery } from '../../../api/queries/supplierQueries'
import BodyLayout from '../../../components/Layout/BodyLayout'
import StatementCheck from '../../../components/Statement/StatementCheck'
import StatementDownload from '../../../components/Statement/StatementDownload'
import StatementSelectPeriods from '../../../components/Statement/StatementSelectPeriods'
import StatementSend from '../../../components/Statement/StatementSend'
import StatementUpload from '../../../components/Statement/StatementUpload'
import SubMenu from '../../../components/UI/Menu/SubMenu'
import NavDetails from '../../../components/UI/NavDetails'
import { UserContext } from '../../../contexts/userContext'
import { useErrorState } from '../../../hooks/useErrorState'
import { monthNumber } from '../../../utils/format/date'

const STATEMENT_STEPS = { download: 0, upload: 1, check: 2, send: 3 }
const STATEMENT_MENU_STEPS = [
  { id: STATEMENT_STEPS.download, name: 'Télécharger mon fichier de collecte', disabled: false },
  { id: STATEMENT_STEPS.upload, name: 'Importer ma collecte', disabled: false },
  { id: STATEMENT_STEPS.check, name: 'Vérifier ma collecte', disabled: true },
  { id: STATEMENT_STEPS.send, name: 'Envoi', disabled: true },
]
export default function Statement() {
  const { error, setErrorState } = useErrorState()
  const [fileToUpload, setFileToUpload] = useState(null)
  const [selectedPeriod, setSelectedPeriod] = useState('')
  const [state, setState] = useState({
    agreements: [],
    supplier: {},
  })
  const [steps, setSteps] = useState({
    allSteps: STATEMENT_MENU_STEPS,
    currentStatementStep: STATEMENT_MENU_STEPS[0],
  })
  const user = useContext(UserContext)

  useEffect(() => {
    API.queryAPI(supplierAgreementByUserQuery(user.userEmail))
      .then((result) => {
        const supplier = {
          id: result.supplierByUser.id,
          name: result.supplierByUser.name,
          agreement: {},
        }
        setState({ supplier, agreements: result.supplierByUser.agreements })
      })
      .catch((errorMessage) => setErrorState(errorMessage))
  }, [])

  useEffect(() => {
    const agreement = _getCurrentAgreement()
    setState({ ...state, supplier: { ...state.supplier, agreement } })
  }, [selectedPeriod])

  function _handleMenuClick(nextStep) {
    const newSteps = [...steps.allSteps]
    newSteps[nextStep].disabled = false
    setSteps({ allSteps: newSteps, currentStatementStep: newSteps[nextStep] })
  }

  function _handleDropFile(file) {
    if (!file) return
    setFileToUpload(file)
  }

  function _getCurrentAgreement() {
    const period = selectedPeriod.split(' ')
    const selectedPeriodDate = new Date(Date.UTC(period[1], monthNumber(period[0]) - 1, 1))
    return state.agreements.find((agreement) => {
      const periodStartingDate = agreement.periodStart.split('-')
      const periodEndingDate = agreement.periodEnd.split('-')
      return (
        selectedPeriodDate >=
          new Date(Date.UTC(periodStartingDate[0], periodStartingDate[1] - 1, periodStartingDate[2])) &&
        selectedPeriodDate <= new Date(Date.UTC(periodEndingDate[0], periodEndingDate[1] - 1, periodEndingDate[2]))
      )
    })
  }

  return (
    <BodyLayout
      error={error}
      navDetails={<NavDetails name="Déclarer mon CA" />}
      menu={
        <Box>
          <SubMenu
            currentItem={steps.currentStatementStep}
            items={steps.allSteps}
            title="Etapes"
            onClick={(step) => setSteps({ ...steps, currentStatementStep: step })}
          />
        </Box>
      }
    >
      <>
        {[STATEMENT_STEPS.download, STATEMENT_STEPS.upload].includes(steps.currentStatementStep.id) && (
          <Box display="flex" justifyContent="flex-end">
            <StatementSelectPeriods agreements={state.agreements} onChange={(value) => setSelectedPeriod(value)} />
          </Box>
        )}
        {steps.currentStatementStep.id === STATEMENT_STEPS.download ? (
          <StatementDownload
            period={selectedPeriod}
            supplier={state.supplier}
            title={steps.currentStatementStep.name}
          />
        ) : steps.currentStatementStep.id === STATEMENT_STEPS.upload ? (
          <StatementUpload
            file={fileToUpload}
            handleDrop={(file) => _handleDropFile(file)}
            hasAgreement={!!state.supplier.agreement?.id}
            isPeriodSelected={selectedPeriod !== ''}
            title={steps.currentStatementStep.name}
            onClick={(nextStep) => _handleMenuClick(nextStep)}
          />
        ) : steps.currentStatementStep.id === STATEMENT_STEPS.check ? (
          <StatementCheck
            agreement={state.supplier.agreement}
            file={fileToUpload}
            title={steps.currentStatementStep.name}
            onClick={(nextStep) => _handleMenuClick(nextStep)}
          />
        ) : (
          steps.currentStatementStep.id === STATEMENT_STEPS.send && (
            <StatementSend data={state.supplier} file={fileToUpload} title={steps.currentStatementStep.name} />
          )
        )}
      </>
    </BodyLayout>
  )
}
