import { Box, Grid, TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import API from '../../../api/api'
import { createSupplierMutation } from '../../../api/mutations/supplierMutations'
import { getUserByRoleQuery } from '../../../api/queries/userQueries'
import { ROLES } from '../../../utils/constants'
import { zipCodeInput } from '../../../utils/form/rules'
import DialogInfo from '../../UI/DialogInfo'
import CommonTextField from '../../UI/Form/CommonTextField'
import ValidationWrapper from '../../UI/Form/ValidationWrapper'

AddSupplierDialog.propTypes = {
  opened: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onValidate: PropTypes.func.isRequired,
}

export default function AddSupplierDialog({ opened, onClose, onValidate }) {
  const [supplier, setSupplier] = useState({ name: '', address: '', city: '', zipcode: '', owner: '' })
  const [owners, setOwners] = useState([])
  const [fieldsValidity, setFieldsValidity] = useState({
    name: false,
    address: false,
    city: false,
    zipcode: false,
    owner: false,
  })

  useEffect(() => {
    API.queryAPI(getUserByRoleQuery(ROLES.owner)).then((owners) => setOwners(owners.userByRole))
  }, [])

  function _handleChange(value, name, isValid) {
    setSupplier({ ...supplier, [name]: value })
    setFieldsValidity({ ...fieldsValidity, [name]: isValid })
  }

  async function _handleValidate() {
    try {
      const newSupplierResult = await API.queryAPI(createSupplierMutation(supplier, null, null))
      onValidate(newSupplierResult.createSupplier, { status: false })
    } catch (errorMessage) {
      onValidate({}, { status: true, message: errorMessage })
    } finally {
      onClose()
    }
  }

  return (
    <DialogInfo title="Ajouter un nouveau fournisseur" open={opened} onClose={onClose}>
      <ValidationWrapper fieldsValidity={fieldsValidity} onValidate={_handleValidate}>
        <Box mt={3} mb={6}>
          <Grid container>
            <CommonTextField name="name" onChange={_handleChange} label="Nom du fournisseur" required />
            <CommonTextField name="address" onChange={_handleChange} label="Adresse de facturation" required />
            <Grid item sm={6}>
              <CommonTextField
                name="zipcode"
                onChange={_handleChange}
                label="Code Postal"
                required
                rules={zipCodeInput}
              />
            </Grid>
            <Grid item sm={6}>
              <CommonTextField name="city" onChange={_handleChange} label="Ville" required />
            </Grid>
            <Autocomplete
              id="newSupplier-owner"
              options={owners}
              getOptionLabel={(option) => `${option.firstname} ${option.lastname}`}
              onChange={(_event, owner) => _handleChange(owner.id, 'owner', true)}
              style={{ width: 300 }}
              renderInput={(params) => <TextField {...params} label="Acheteur" variant="outlined" required />}
            />
          </Grid>
        </Box>
      </ValidationWrapper>
    </DialogInfo>
  )
}
