import { Box, IconButton, Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { monthYearDate } from '../../../utils/format/date'
import { sortObjectsArray } from '../../../utils/functions/common'
import LoadingTable from '../../UI/LoadingTable'

LastStatementsTable.propTypes = {
  suppliers: PropTypes.array.isRequired,
}

export default function LastStatementsTable({ suppliers }) {
  const [lastStatements, setLastStatements] = useState([])

  useEffect(() => {
    const statements = []
    suppliers?.forEach((supplier) => {
      const statementsLength = supplier.statements.length
      if (statementsLength === 0) return
      statements.push({
        supplier: { id: supplier.id, name: supplier.name },
        date: `${monthYearDate(supplier.statements[statementsLength - 1]?.period)}`,
        creationDate: supplier.statements[statementsLength - 1]?.file.creationDate,
      })
    })
    sortObjectsArray(statements, 'creationDate')
    setLastStatements(statements.reverse())
  }, [suppliers])

  return (
    <>
      {!lastStatements.length ? (
        <LoadingTable />
      ) : (
        <TableContainer style={{ paddingBottom: '50px' }}>
          <Table>
            <TableBody>
              {lastStatements.map((file, index) => (
                <TableRow key={index}>
                  <TableCell>{file.supplier.name}</TableCell>
                  <TableCell>
                    <Box fontStyle="italic">déclaration de {file.date}</Box>
                  </TableCell>
                  <TableCell align="right">
                    <IconButton aria-label="supplier" component={Link} to={`/backoffice/suppliers/${file.supplier.id}`}>
                      <NavigateNextIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  )
}
