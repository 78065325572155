import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import API from '../../../api/api'
import { loginMutation } from '../../../api/mutations/userMutations'
import { UserContext } from '../../../contexts/userContext'
import { useErrorState } from '../../../hooks/useErrorState'
import { SUPPLIERS_ROLES } from '../../../utils/constants'
import MainLayout from '../../Layout/MainLayout'
import LoginUser from '../../LoginUser'

SupplierTemplate.propTypes = {
  children: PropTypes.element,
}

export default function SupplierTemplate({ children }) {
  const [user, setUser] = useState({})
  const { error, setErrorState } = useErrorState()

  useEffect(() => {
    const localUser = localStorage.getItem('user')
    try {
      JSON.parse(localUser) // try to parse to confirm it's not a string because of previous version
      if (Object.entries(user).length === 0 && localUser && Object.entries(localUser).length > 0)
        setUser(JSON.parse(localStorage.getItem('user')))
    } catch {
      handleSignOut()
    }
  }, [])

  const signIn = async (email, password) => {
    try {
      const userResult = await API.queryAPI(loginMutation(email, password))
      if (SUPPLIERS_ROLES.includes(userResult.login.role)) {
        setUser({ ...userResult.login })
        localStorage.setItem('user', JSON.stringify(userResult.login))
      }
    } catch (errorMessage) {
      setErrorState(errorMessage)
    }
  }

  const handleSignOut = () => {
    localStorage.removeItem('user')
    setUser({})
  }

  return (
    <>
      {Object.entries(user).length > 0 ? (
        <UserContext.Provider
          value={{ userEmail: user.email, userRole: user.role, userName: `${user.firstname} ${user.lastname}` }}
        >
          <MainLayout user={user} onSignOut={handleSignOut}>
            {children}
          </MainLayout>
        </UserContext.Provider>
      ) : (
        !localStorage.getItem('user') && <LoginUser loginWithSSO={false} loginError={error} onSignIn={signIn} />
      )}
    </>
  )
}
