import { Box, Grid } from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'
import ErrorContent from '../../UI/ErrorContent'

BodyLayout.propTypes = {
  children: PropTypes.element,
  error: PropTypes.shape({
    status: PropTypes.bool.isRequired,
    message: PropTypes.string.isRequired,
  }),
  navDetails: PropTypes.element,
  menu: PropTypes.element,
}

export default function BodyLayout({ children, error, menu, navDetails }) {
  return (
    <div>
      {error?.status ? (
        <ErrorContent message={error.message} />
      ) : (
        <>
          {navDetails}
          {menu ? (
            <Grid container spacing={2} style={{ paddingLeft: 20, paddingRight: 20 }}>
              <Grid item xs={12} sm={3} style={{ paddingRight: 15 }}>
                <Box px={3}>{menu}</Box>
              </Grid>
              <Grid item xs={12} sm={9}>
                {children}
              </Grid>
            </Grid>
          ) : (
            children
          )}
        </>
      )}
    </div>
  )
}
