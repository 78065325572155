import { ContractType, StatusType } from '../types'

export const createContractMutation = (supplierId, contract, fileName) => {
  return `mutation {
    createContract(
      startDate: "${contract.startDate}",
      endDate: "${contract.endDate}",
      expectedVolume: ${contract.volume},
      hectoliters: ${!!contract.volumeType},
      fileName: "${fileName}",
      supplierId: ${supplierId},
      salesPointCode: ${contract.salesPointCode},
      businessUnitId: ${contract.businessUnitCode},
      salesPointName: "${contract.salesPointName}",
      salesPointAddress: "${contract.salesPointAddress}",
      salesPointZipcode: "${contract.salesPointZipcode}",
      salesPointCity: "${contract.salesPointCity}",
    )
    ${ContractType}
  }`
}

export const updateContractStatusMutation = (contractId, statusId, message = null) => {
  return `mutation {
    updateContractStatus(
      contractId: ${contractId},
      statusId: ${statusId}
      message: """${message}"""
  ) {
      ${StatusType}
    }
  }`
}
