import { Box, Button } from '@material-ui/core'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import API from '../../../api/api'
import { getContractsBySupplier } from '../../../api/queries/contractQueries'
import { useAlert } from '../../../hooks/useAlert'
import createContratsReport from '../../../services/excel/createContratsReport'
import Alert from '../../UI/Alert'
import ContractDialog from '../ContractDialog'
import ContractRefusalDialog from '../ContractRefusalDialog'
import ContractsTable from '../ContractsTable'
import ContractValidationDialog from '../ContractValidationDialog'

ContractsView.propTypes = {
  supplier: PropTypes.object.isRequired,
  onDataLoaded: PropTypes.func.isRequired,
}
ContractsView.defaultProps = {
  supplier: {},
}

export default function ContractsView({ supplier, onDataLoaded }) {
  const location = useLocation()
  const { alert, setError, setReset, setSuccess } = useAlert()
  const [contracts, setContracts] = useState([])
  const [currentContract, setCurrentContract] = useState({})
  const [openContractDialog, setOpenContractDialog] = useState(false)
  const [openValidationDialog, setOpenValidationDialog] = useState(false)
  const [openRefusalDialog, setOpenRefusalDialog] = useState(false)
  const [selectedContracts, setSelectedContrats] = useState([])

  useEffect(() => {
    if (Object.entries(supplier).length === 0) return
    API.queryAPI(getContractsBySupplier(supplier.id))
      .then((result) => setContracts(result.contractsBySupplier))
      .catch((errorMessage) => onDataLoaded(errorMessage))
  }, [supplier])

  function _handleNewContract(newContract, error) {
    if (error.status) {
      setError()
      return
    }
    setContracts([...contracts, newContract])
    setSuccess()
  }

  function _handleStatusUpdate(newContract, error) {
    if (error.status) {
      setError(error.message)
      return
    }
    const contractIndex = contracts.findIndex((current) => current.id === newContract.id)
    const newContracts = Object.values({ ...contracts, [contractIndex]: newContract })
    setContracts(newContracts)
    setSuccess()
  }

  async function _handleContractsDownload() {
    try {
      const exportContracts = contracts.filter((contract) => selectedContracts.includes(contract.id))
      await createContratsReport(exportContracts)
    } catch {
      setError()
    }
  }

  return (
    <>
      <Alert onClose={setReset} open={alert.open} success={alert.success} message={alert.message} />
      <Box display="flex" justifyContent="flex-end" m={2}>
        <Box mr={2}>
          <Button
            variant="contained"
            color="secondary"
            disabled={selectedContracts.length === 0}
            onClick={_handleContractsDownload}
          >
            Exporter au format excel
          </Button>
        </Box>
        <Box>
          <Button variant="contained" color="primary" onClick={() => setOpenContractDialog(true)}>
            Ajouter un contrat
          </Button>
        </Box>
      </Box>
      <Box px={2}>
        <ContractsTable
          contracts={contracts}
          validation={location.pathname.includes('backoffice')}
          onOpenValidation={(contract) => {
            setCurrentContract(contract)
            setOpenValidationDialog(true)
          }}
          onOpenRefusal={(contract) => {
            setCurrentContract(contract)
            setOpenRefusalDialog(true)
          }}
          onSelected={(contracts) => setSelectedContrats(contracts)}
        />
      </Box>
      {openContractDialog && (
        <ContractDialog
          opened={openContractDialog}
          supplier={supplier}
          onClose={() => setOpenContractDialog(false)}
          onValidate={(newContract, error) => _handleNewContract(newContract, error)}
        />
      )}
      {openValidationDialog && (
        <ContractValidationDialog
          contract={currentContract}
          opened={openValidationDialog}
          onClose={() => setOpenValidationDialog(false)}
          onValidate={(contract, error) => _handleStatusUpdate(contract, error)}
        />
      )}
      {openRefusalDialog && (
        <ContractRefusalDialog
          contract={currentContract}
          opened={openRefusalDialog}
          onClose={() => setOpenRefusalDialog(false)}
          onValidate={(contract, error) => _handleStatusUpdate(contract, error)}
        />
      )}
    </>
  )
}
