import { Snackbar } from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'

Alert.propTypes = {
  message: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  success: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default function Alert({ message = '', open = false, success = true, onClose }) {
  const [isOpen, setOpen] = useState(open)

  useEffect(() => setOpen(open), [open])

  function _handleSnackClose(_event, reason) {
    if (reason === 'clickaway') return
    onClose()
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isOpen}
      autoHideDuration={5000}
      onClose={_handleSnackClose}
    >
      <MuiAlert elevation={6} variant="filled" onClose={_handleSnackClose} severity={success ? 'success' : 'error'}>
        {message}
      </MuiAlert>
    </Snackbar>
  )
}
