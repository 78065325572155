import { Box, Card, CardActionArea, CardContent, Grid } from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'
import './File.css'

File.propTypes = {
  children: PropTypes.element.isRequired,
  icon: PropTypes.element.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default function File({ children, icon, onClick }) {
  return (
    <Card variant="outlined" className="file">
      <CardActionArea onClick={() => onClick()}>
        <CardContent>
          <Grid container direction="column" alignItems="center">
            {icon}
            <Box mb={3}>{children}</Box>
          </Grid>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}
