import { BlobServiceClient } from '@azure/storage-blob'
import FileSaver from 'file-saver'
import API from '../../api/api'
import { getSasQuery } from '../../api/queries/storageQueries'

export default async function (fileUrl, container, hasContainerByEnv = true) {
  try {
    const containerByEnv = hasContainerByEnv ? `${container}-${process.env.REACT_APP_ENV}` : container
    const sasToken = await API.queryAPI(getSasQuery(containerByEnv))

    const blobService = new BlobServiceClient(`https://portalfb.blob.core.windows.net/?${sasToken.getStorageKey.key}`)
    const containerClient = blobService.getContainerClient(containerByEnv)
    const blobClient = containerClient.getBlockBlobClient(fileUrl)
    const downloadBlob = await blobClient.download()

    FileSaver.saveAs(await downloadBlob.blobBody, fileUrl.replace('/', '_'))
  } catch (e) {
    return e
  }
}
