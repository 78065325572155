export const loginMutation = (email, password) => {
  return `mutation {
    login(email: "${email}", password: "${password}") {
      email
      firstname
      lastname
      role
    }
  }`
}

export const askPasswordUpdateMutation = (email) => {
  return `mutation {
    askPasswordUpdate(email: "${email}") {
      done
    }
  }`
}

export const updateUserMutation = (user) => {
  return `mutation {
    updateUser(id: ${user.id}, firstname: "${user.firstname}", lastname: "${user.lastname}", email: "${user.email}") {
      id
    }
  }`
}

export const passwordMutation = (hash, password) => {
  return `mutation {
    updatePassword(hash: "${hash}", newPassword: "${password}") {
      done
    }
  }`
}

export const createBackOfficeUserMutation = (user) => {
  return `mutation {
    createBackOfficeUser(
      firstname: "${user.firstname}",
      lastname: "${user.lastname}",
      email: "${user.email}",
      role: ${user.role},
    ){
      id
      firstname
      lastname
      email
      role
    }
  }`
}

export const createUserMutation = (user) => {
  return `mutation {
    createUser(
      firstname: "${user.firstname}",
      lastname: "${user.lastname}",
      email: "${user.email}",
      role: ${user.role},
    ){
      id
    }
  }`
}

export const deleteUserMutation = (id) => {
  return `mutation {
    deleteUser(id: ${id}) {
      id
    }
  }`
}
