import { Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useHistory } from 'react-router'
import API from '../../../api/api'
import { saveStatement } from '../../../api/mutations/statementMutations'
import { useAlert } from '../../../hooks/useAlert'
import { getPeriodFromFile } from '../../../services/excel/checkStatementUpload'
import { getExcelWorksheet } from '../../../services/excel/common'
import convertBlobToBase64 from '../../../services/file/convert'
import uploadFile from '../../../services/file/upload'
import { FILE_TYPES, MONTHS } from '../../../utils/constants'
import Alert from '../../UI/Alert'
import ValidationWrapper from '../../UI/Form/ValidationWrapper'
import Subtitle from '../../UI/SubTitle'

StatementSend.propTypes = {
  data: PropTypes.object,
  file: PropTypes.object,
  title: PropTypes.string.isRequired,
}

export default function StatementSend({ data, file, title }) {
  const history = useHistory()
  const { alert, setError, setReset } = useAlert()
  const [isLoading, setLoading] = useState(false)

  async function _getPeriod(file) {
    const worksheet = await getExcelWorksheet(file)
    const period = getPeriodFromFile(worksheet).split(' ')
    const month = MONTHS.findIndex((currentMonth) => currentMonth === period[0])
    if (month === -1) throw 'month not found'
    return `${period[1]}-${month + 1}`
  }
  async function _sendFile() {
    if (!file) return
    try {
      setLoading(true)
      const filePath = await uploadFile(file, FILE_TYPES.statement, data.name)
      const period = await _getPeriod(file)
      const statementResult = await API.queryAPI(saveStatement(filePath, filePath.split('/')[1], period, data.id))

      const stringFile = await convertBlobToBase64(file)
      API.queryFunctions('StatementUploadTrigger', process.env.REACT_APP_STATEMENT_FUNCTION_CODE, {
        file: stringFile,
        statementId: statementResult.saveStatement.id,
        supplierId: statementResult.saveStatement.supplierId,
        period: period,
        isUpdate: statementResult.saveStatement.isUpdate,
      })

      history.push({
        pathname: '/',
        state: {
          isStatementSent: true,
        },
      })
    } catch {
      setLoading(false)
      setError()
    }
  }

  return (
    <div>
      <Alert onClose={setReset} open={alert.open} success={alert.success} message={alert.message} />
      <Subtitle text={title} />
      <ValidationWrapper isLoading={isLoading} onValidate={_sendFile}>
        <Typography variant="body2">Votre collecte est sur le point d’être envoyée à France Boissons.</Typography>
      </ValidationWrapper>
    </div>
  )
}
