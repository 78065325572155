import { Box, Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import PropTypes from 'prop-types'
import Title from '../Title'

DialogInfo.propTypes = {
  children: PropTypes.element,
  subtitle: PropTypes.string,
  title: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

DialogInfo.defaultProps = {
  fullWidth: true,
}

export default function DialogInfo({ children, fullWidth, subtitle, title, open, onClose }) {
  return (
    <Dialog fullWidth={fullWidth} maxWidth="md" open={open} onClose={onClose} aria-labelledby="dialog-info-title">
      <DialogTitle id="customized-dialog-title">
        <Box display="flex">
          <Box flexGrow={1} mt={1}>
            {title && <Title text={title} variant="h6" className="dialog" />}
          </Box>
          <IconButton aria-label="fermer" onClick={onClose} color="inherit" size="small">
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box fontStyle="italic" mb={1}>
          <Typography variant="caption" gutterBottom>
            {subtitle}
          </Typography>
        </Box>
        {children}
      </DialogContent>
    </Dialog>
  )
}
