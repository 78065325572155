import React, { useEffect, useState } from 'react'
import API from '../../../api/api'
import { getAllSuppliersHistoryQuery } from '../../../api/queries/supplierQueries'
import HistoryMenu from '../../../components/History/HistoryMenu'
import HistoryView from '../../../components/History/HistoryView'
import BodyLayout from '../../../components/Layout/BodyLayout'
import Alert from '../../../components/UI/Alert'
import { useAlert } from '../../../hooks/useAlert'
import { useErrorState } from '../../../hooks/useErrorState'
import createStatementsReport from '../../../services/excel/createStatementsReport'
import { sortObjectsArray } from '../../../utils/functions/common'
import {
  getFiles,
  getFiltersByLastPeriod,
  getLastPeriodArray,
  getPeriodsByYears,
  getTurnovers,
} from '../../../utils/functions/history'
import { getCurrentTurnovers } from './functions'

export default function History() {
  const { alert, setError, setReset } = useAlert()
  const { error, setErrorState } = useErrorState()
  const [state, setState] = useState({
    currentTurnovers: [],
    files: [],
    periods: [],
    suppliers: [],
    turnovers: [],
    filteredFiles: undefined,
    filteredTurnovers: [],
    selectedPeriods: [],
    suppliersIdNameAndStatements: [],
  })

  useEffect(() => {
    API.queryAPI(getAllSuppliersHistoryQuery)
      .then(({ suppliers }) => {
        const periods = [
          ...new Set(suppliers.map((supplier) => supplier.statements.map((statement) => statement.period)).flat()),
        ]
        const sortedPeriodsByYear = getPeriodsByYears(periods)
        const filesBySuppliersAndPeriods = suppliers.map((supplier) => getFiles(supplier)).flat()
        const turnoversBySuppliersAndPeriods = suppliers.map((supplier) => getTurnovers(supplier)).flat()
        const sortedSuppliers = sortObjectsArray(
          suppliers.map((supplier) => ({
            id: supplier.id,
            value: supplier.name,
            ownerId: supplier.owner.id,
            periods: supplier.statements.map((statement) => statement.period),
          })),
          'value'
        )
        const [lastPeriod] = getLastPeriodArray(sortedPeriodsByYear)
        const suppliersIdNameAndStatements = suppliers
          .filter((supplier) => supplier.statements.length > 0)
          .map((supplier) => ({
            id: supplier.id,
            name: supplier.name,
            statements: supplier.statements,
          }))
        const { filteredFiles, filteredTurnovers } = getFiltersByLastPeriod(
          lastPeriod,
          filesBySuppliersAndPeriods,
          turnoversBySuppliersAndPeriods
        )
        const currentTurnovers = getCurrentTurnovers(
          suppliers.map((supplier) => supplier.id),
          turnoversBySuppliersAndPeriods,
          sortedPeriodsByYear
        )
        setState({
          currentTurnovers,
          files: filesBySuppliersAndPeriods,
          periods: sortedPeriodsByYear,
          suppliers: [{ id: 0, value: 'Tous' }, ...sortedSuppliers],
          turnovers: turnoversBySuppliersAndPeriods,
          filteredFiles,
          filteredTurnovers,
          selectedPeriods: [lastPeriod],
          suppliersIdNameAndStatements,
        })
      })
      .catch((errorMessage) => setErrorState(errorMessage))
  }, [])

  const _handleOnChange = (currentSuppliersId, filteredFiles, filteredTurnovers, selectedPeriods) => {
    const currentTurnovers = getCurrentTurnovers(currentSuppliersId, state.turnovers, state.periods)
    setState({ ...state, currentTurnovers, filteredFiles, filteredTurnovers, selectedPeriods })
  }

  const _handleExport = (selectedSuppliersIds) => {
    try {
      const suppliers =
        selectedSuppliersIds.length > 0
          ? state.suppliersIdNameAndStatements.filter((supplier) => selectedSuppliersIds.includes(supplier.id))
          : state.suppliersIdNameAndStatements
      createStatementsReport(state.selectedPeriods, suppliers)
    } catch {
      setError()
    }
  }

  return (
    <BodyLayout error={error}>
      <Alert message={alert.message} open={alert.open} success={alert.success} onClose={setReset} />
      <HistoryView
        menu={
          <HistoryMenu
            displayFilters
            files={state.files}
            periods={state.periods}
            selectedPeriods={state.selectedPeriods}
            suppliers={state.suppliers}
            turnovers={state.turnovers}
            onChange={({ currentSuppliersId, filteredFiles, filteredTurnovers, selectedPeriods }) =>
              _handleOnChange(currentSuppliersId, filteredFiles, filteredTurnovers, selectedPeriods)
            }
            onDownload={(selectedSuppliers) => _handleExport(selectedSuppliers)}
          />
        }
        files={state.filteredFiles}
        selectedTurnovers={state.filteredTurnovers}
        turnovers={state.currentTurnovers}
      />
    </BodyLayout>
  )
}
