import { Box, Button, Typography } from '@material-ui/core'
import { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import API from '../../api/api'
import { passwordMutation } from '../../api/mutations/userMutations'
import Alert from '../../components/UI/Alert'
import CommonTextField from '../../components/UI/Form/CommonTextField'
import { useAlert } from '../../hooks/useAlert'

export default function UpdatePassword() {
  const [state, setState] = useState({ check: '', password: '', passwordError: false })
  const { alert, setError, setReset, setSuccess } = useAlert()
  const location = useLocation()
  const history = useHistory()

  // TODO: handle password check better
  const updatePassword = async () => {
    if (state.check === '' || state.check !== state.password) {
      setState({ check: '', password: '', passwordError: true })
      return
    }
    try {
      await API.queryAPI(passwordMutation(location.search.replace('?', ''), state.password))
      setSuccess()
      setTimeout(() => history.replace('/'), 1000)
    } catch (errorMessage) {
      setError(errorMessage)
    }
  }

  return (
    <>
      <Alert onClose={setReset} open={alert.open} success={alert.success} message={alert.message} />
      <Typography variant="h4">Modifier votre mot de passe</Typography>
      <Box mt={2}>
        <CommonTextField
          name="password"
          value={state.password}
          onChange={(value) => setState({ ...state, password: value })}
          label="Mot de passe"
          required
          type="password"
          customError={state.passwordError}
        />
        <CommonTextField
          name="check"
          value={state.check}
          onChange={(value) => setState({ ...state, check: value })}
          label="Confirmez votre mot de passe"
          required
          type="password"
          customError={state.passwordError}
        />
        <Button onClick={updatePassword} variant="contained" color="secondary" fullWidth>
          Modifier votre mot de passe
        </Button>
      </Box>
    </>
  )
}
