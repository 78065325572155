import { Box, Button, Menu, MenuItem, Paper } from '@material-ui/core'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import './navBar.css'

NavBar.propTypes = {
  user: PropTypes.object,
  onSignOut: PropTypes.func,
}

export default function NavBar({ user, onSignOut }) {
  const [anchorEl, setAnchor] = useState(null)

  return (
    <Paper elevation={0} className="navBarContainer">
      <Box display="flex">
        <Box flexGrow={1}>
          <img src={'/FB_logo.png'} alt="Logo France Boissons" className="navBarLogo" />
        </Box>
        <Box>
          <Button
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={(event) => setAnchor(event.currentTarget)}
            startIcon={<AccountCircleIcon />}
          >
            {user ? `${user.firstname} ${user.lastname}` : ''}
          </Button>
          <Menu
            id="navbar-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={() => setAnchor(null)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            getContentAnchorEl={null}
          >
            <MenuItem onClick={onSignOut}>Déconnexion</MenuItem>
          </Menu>
        </Box>
      </Box>
    </Paper>
  )
}
