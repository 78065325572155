import { Box, LinearProgress, TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import React, { useEffect, useState } from 'react'
import API from '../../../api/api'
import { getAllSuppliersQuery } from '../../../api/queries/supplierQueries'
import { getUserByRoleQuery } from '../../../api/queries/userQueries'
import BodyLayout from '../../../components/Layout/BodyLayout'
import SupplierView from '../../../components/Supplier/SupplierView'
import SubMenu from '../../../components/UI/Menu/SubMenu'
import NavDetails from '../../../components/UI/NavDetails'
import UsersSelect from '../../../components/UI/UsersSelect'
import { useErrorState } from '../../../hooks/useErrorState'
import { ROLES } from '../../../utils/constants'
import { getSuppliersByOwner, sortObjectsArray } from '../../../utils/functions/common'

export default function Suppliers(props) {
  const { id } = props.match.params
  const { error, setErrorState } = useErrorState()
  const [state, setState] = useState({
    currentSupplier: {},
    displayedSuppliers: [],
    owners: [],
    ownerKey: 1,
    suppliers: [],
    isLoading: false,
  })

  useEffect(() => {
    setState({ ...state, isLoading: true })
    API.queryAPI(getAllSuppliersQuery)
      .then(async (suppliersResult) => {
        const suppliers = sortObjectsArray(suppliersResult.suppliers, 'name')
        const ownersResult = await API.queryAPI(getUserByRoleQuery(ROLES.owner))
        setState({
          ...state,
          displayedSuppliers: suppliers,
          owners: ownersResult.userByRole,
          suppliers: suppliers,
          isLoading: false,
        })
      })
      .catch((errorMessage) => setErrorState(errorMessage))
  }, [])

  useEffect(() => {
    if (!id || state.displayedSuppliers.length === 0) return
    const currentSupplier = state.displayedSuppliers.find((supplier) => supplier.id === parseInt(id))
    setState({ ...state, currentSupplier })
  }, [id, state.displayedSuppliers])

  function _handleOwnerFilter(ownerId) {
    const suppliersByOwner = getSuppliersByOwner(ownerId, state.suppliers)
    setState({
      ...state,
      displayedSuppliers: suppliersByOwner,
      ownerKey: ownerId === 0 ? state.ownerKey + 1 : state.ownerKey,
    })
  }

  function _handleSupplierFilter(newSupplier) {
    if (newSupplier === null) setState({ ...state, displayedSuppliers: state.suppliers })
    else
      setState({
        ...state,
        displayedSuppliers: [newSupplier],
        currentSupplier: newSupplier,
        currentOwner: state.ownerKey + 1,
      })
  }

  function _handleAgreementDeleted(agreementId) {
    const newAgreements = state.currentSupplier.agreements.filter((agreement) => agreement.id !== agreementId)
    setState({ ...state, currentSupplier: { ...state.currentSupplier, agreements: newAgreements } })
  }

  return (
    <BodyLayout
      error={error}
      navDetails={<NavDetails name="Fournisseurs" />}
      menu={
        <div>
          {/* Owners filter */}
          <UsersSelect
            key={state.ownerKey}
            all
            items={state.owners}
            label="Acheteurs"
            onUserChange={(ownerId) => _handleOwnerFilter(ownerId)}
          />
          {/* Supplier filter */}
          <Box mb={4}>
            <Autocomplete
              id="search-supplier"
              options={state.displayedSuppliers}
              getOptionLabel={(option) => option.name}
              fullWidth
              onChange={(_event, newSupplier) => _handleSupplierFilter(newSupplier)}
              renderInput={(params) => <TextField {...params} label="Rechercher" variant="outlined" />}
            />
          </Box>
          {/* Supplier choice */}
          <Box>
            {state.isLoading ? (
              <LinearProgress />
            ) : (
              <SubMenu
                title="Fournisseurs"
                items={state.displayedSuppliers}
                currentItem={state.currentSupplier}
                onClick={(supplier) => setState({ ...state, currentSupplier: supplier })}
              />
            )}
          </Box>
        </div>
      }
    >
      <>
        {Object.keys(state.currentSupplier).length > 0 && (
          <SupplierView
            supplier={state.currentSupplier}
            onAgreementDelete={(agreementId) => _handleAgreementDeleted(agreementId)}
          />
        )}
      </>
    </BodyLayout>
  )
}
