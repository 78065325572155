import { Box, LinearProgress } from '@material-ui/core'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { sumStatement } from '../../../services/excel/statementSums'
import FigureCards from '../../UI/FigureCards'

StatementFileSum.propTypes = {
  agreement: PropTypes.object.isRequired,
  file: PropTypes.object.isRequired,
}

export default function StatementFileSum({ agreement, file }) {
  const [sums, setSums] = useState({
    turnover: <LinearProgress />,
    totalDiscount: <LinearProgress />,
    natDiscount: <LinearProgress />,
    localDiscount: <LinearProgress />,
  })

  useEffect(() => {
    sumStatement(file, agreement).then((res) => {
      setSums(res)
    })
  }, [])

  return (
    <Box mt={4}>
      <FigureCards
        title="Totaux"
        items={[
          { subtitle: "Chiffre d'affaires et volume", figure: sums.turnover },
          { subtitle: "Remise en fin d'année", figure: sums.totalDiscount },
          { subtitle: "Remise de fin d'année FB SAS", figure: sums.natDiscount },
          { subtitle: "Remise de fin d'année FB Filiales", figure: sums.localDiscount },
        ]}
      />
    </Box>
  )
}
