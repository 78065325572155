import React from 'react'
import ContractsView from '../../../components/Contracts/ContratsView'
import BodyLayout from '../../../components/Layout/BodyLayout'
import NavDetails from '../../../components/UI/NavDetails'
import { useErrorState } from '../../../hooks/useErrorState'

export default function Contracts(props) {
  const { error, setErrorState } = useErrorState()

  return (
    <BodyLayout error={error}>
      <NavDetails name="Contrats" />
      <ContractsView
        supplier={props.location?.state?.supplier ?? {}}
        onDataLoaded={(errorMessage) => setErrorState(errorMessage)}
      />
    </BodyLayout>
  )
}
