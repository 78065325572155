import { Box, Button, IconButton, Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import DownloadIcon from '@material-ui/icons/GetApp'
import { useState } from 'react'
import API from '../../../api/api'
import { deleteAgreementMutation } from '../../../api/mutations/agreementMutations'
import downloadFile from '../../../services/file/download'
import { FILE_TYPES } from '../../../utils/constants'
import { formatDate } from '../../../utils/format/date'
import DialogInfo from '../../UI/DialogInfo'
import ValidationWrapper from '../../UI/Form/ValidationWrapper'

export default function SupplierHistoryDialog({ supplier, opened, onDelete, onClose }) {
  const [agreementIdToDelete, setAgreementIdToDelete] = useState(null)
  const [isDialogOpened, setIsDialogOpened] = useState(false)

  function _handleDelete() {
    API.queryAPI(deleteAgreementMutation(agreementIdToDelete))
      .then(() => onDelete(agreementIdToDelete, { status: false }))
      .catch((errorMessage) => onDelete(agreementIdToDelete, { status: true, message: errorMessage }))
      .finally(() => onClose())
  }

  return (
    <>
      <DialogInfo title="Historique des accords" open={opened} onClose={onClose}>
        <TableContainer style={{ paddingBottom: '50px' }}>
          <Table>
            <TableBody>
              {supplier.agreements.map((agreement, index) => (
                <TableRow key={index}>
                  <TableCell>
                    {`${supplier.name} - ${agreement.periodStart.split('-')[1]}/${
                      agreement.periodStart.split('-')[0]
                    } - ${agreement.periodEnd.split('-')[1]}/${agreement.periodEnd.split('-')[0]}`}{' '}
                  </TableCell>
                  <TableCell>chargé le {formatDate(agreement.annex.creationDate)}</TableCell>
                  <TableCell align="right">
                    <IconButton
                      aria-label="download"
                      onClick={() => downloadFile(agreement.annex.url, FILE_TYPES.annex)}
                    >
                      <DownloadIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell align="right">
                    <Button
                      variant="contained"
                      color="secondary"
                      startIcon={<DeleteIcon />}
                      onClick={() => {
                        setAgreementIdToDelete(agreement.id)
                        setIsDialogOpened(true)
                      }}
                    >
                      Supprimer
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogInfo>

      <DialogInfo title="" open={isDialogOpened} onClose={() => setIsDialogOpened(false)} fullWidth={false}>
        <ValidationWrapper onValidate={() => _handleDelete()}>
          <Box>Êtes-vous sûr·e de vouloir supprimer définitivement cet accord ?</Box>
          <Box mb={2}>Cela entraînera également la suppression de toutes les déclarations qui lui sont liées.</Box>
        </ValidationWrapper>
      </DialogInfo>
    </>
  )
}
