import { InteractionRequiredAuthError, InteractionStatus } from '@azure/msal-browser'
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import API from '../../../api/api'
import { getUserByEmailQuery } from '../../../api/queries/userQueries'
import { UserContext } from '../../../contexts/userContext'
import { useErrorState } from '../../../hooks/useErrorState'
import { apiScopeRequest } from '../../../services/msal'
import { ALERT_MESSAGE } from '../../../utils/constants'
import MainLayout from '../../Layout/MainLayout'
import LoginUser from '../../LoginUser'
import ErrorContent from '../../UI/ErrorContent'

BackOfficeTemplate.propTypes = {
  children: PropTypes.element,
}

export default function BackOfficeTemplate({ children }) {
  const { accounts, inProgress, instance } = useMsal()
  const [user, setUser] = useState({})
  const { error, setErrorState } = useErrorState()

  useEffect(() => {
    if (inProgress === InteractionStatus.None && accounts.length > 0) {
      const request = {
        ...apiScopeRequest,
        account: accounts[0],
      }
      instance
        .acquireTokenSilent(request)
        .then((accessTokenResponse) => {
          localStorage.setItem('token', accessTokenResponse.accessToken)
          if (accounts[0]?.username) {
            API.queryAPI(getUserByEmailQuery(accounts[0]?.username))
              .then((userResult) => setUser(userResult.user))
              .catch((errorMessage) => setErrorState(errorMessage))
          } else setErrorState(ALERT_MESSAGE.error)
        })
        .catch(async (e) => {
          if (e instanceof InteractionRequiredAuthError) {
            await instance.acquireTokenRedirect(request)
          }
          throw e
        })
    }
  }, [accounts, inProgress, instance])

  const handleSignOut = () => {
    instance.logoutRedirect()
  }

  return (
    <>
      <AuthenticatedTemplate>
        {error.status ? (
          <MainLayout>
            <ErrorContent message={error.message} />
          </MainLayout>
        ) : (
          <UserContext.Provider
            value={{ userEmail: user.email, userRole: user.role, userName: `${user.firstname} ${user.lastname}` }}
          >
            <MainLayout user={user} onSignOut={handleSignOut}>
              {children}
            </MainLayout>
          </UserContext.Provider>
        )}
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <LoginUser loginWithSSO={true} onSignIn={() => instance.loginRedirect()} />
      </UnauthenticatedTemplate>
    </>
  )
}
