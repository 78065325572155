import FileSaver from 'file-saver'
import { REGIONS } from '../../utils/constants'
import { monthYearDate } from '../../utils/format/date'
import { sortObjectsArray } from '../../utils/functions/common'
import { setBackGroundColor, setBold, setCellNumberFormat } from './common'
import {
  addHeaderRow,
  addTableHeader,
  addTableRow,
  addTableTotalRow,
  setColumnsWidth,
} from './commonStatementFunctions'
const Excel = require('exceljs')

const totalColor = '95B3D7'
const cellsAmounts = ['E', 'F', 'G', 'H', 'I', 'J', 'K', 'L']

const _getCoopGroups = (amounts) =>
  sortObjectsArray([...new Map(amounts.map((amount) => [amount.coopGroup.id, amount.coopGroup])).values()], 'name')

function _createSupplierStatementsReport(worksheet, statement, row, rowWithValues) {
  const startingRow = row
  const coopGroups = _getCoopGroups(statement.amounts)

  const headerCellsValues = {
    A: monthYearDate(statement.period),
    E: 'CA et volume déclarés',
    O: monthYearDate(statement.period),
    P: 'Montant Coop Filiales',
    Y: 'Montant Coop SAS',
    AH: 'Montant Coop Total',
  }
  addHeaderRow(worksheet, row, headerCellsValues, ['E', 'M', 'P', 'W', 'Y', 'AF', 'AH', 'AO'])
  addTableHeader(worksheet, row + 1)

  row = row + 2
  rowWithValues.push(row)
  for (const coopGroup of coopGroups) {
    const filteredAmounts = statement.amounts.filter((amount) => amount.coopGroup.id === coopGroup.id)
    const amounts = REGIONS.map((region) => filteredAmounts.find((amount) => amount.region === region.id)?.amount ?? 0)
    addTableRow(worksheet, coopGroup, amounts, row)
    row++
  }

  addTableTotalRow(worksheet, startingRow, row)
  worksheet.addRow()

  return row + 2
}

function _createTotalTable(worksheet, row, rowWithValues, statementAmounts) {
  const startingRow = row
  const coopGroups = _getCoopGroups(statementAmounts)

  // Add header row
  worksheet.addRow(['Total'])
  setBold(worksheet, row, true)
  worksheet.getRow(row).alignment = { vertical: 'middle' }
  setBackGroundColor(worksheet, `A${row}`, totalColor)
  worksheet.mergeCells(`A${row}:AP${row}`)
  row++

  addTableHeader(worksheet, row)

  const amountsData = []
  for (const [index, coopGroup] of coopGroups.entries()) {
    row++
    const cellsWithFormula = cellsAmounts.map((cell) => ({
      formula: rowWithValues
        .map((rowNumber) => `${cell}${rowNumber + index}`)
        .toString()
        .replaceAll(',', '+'),
    }))
    addTableRow(worksheet, coopGroup, cellsWithFormula, row)

    amountsData.push({
      coopGroup: coopGroup.name,
      cellNumber: row,
    })
  }
  addTableTotalRow(worksheet, startingRow, row + 1)
  worksheet.addRow()

  return { newRowValue: row + 3, amountsData }
}

function _setTotalSupplier(
  worksheet,
  row,
  rowWithValues,
  statementAmounts,
  supplierName,
  totalAmountsCellsBySuppliers
) {
  const { newRowValue, amountsData } = _createTotalTable(worksheet, row, rowWithValues, statementAmounts)
  const existingTotalIndex = totalAmountsCellsBySuppliers.findIndex((total) => total.supplier === supplierName)
  if (existingTotalIndex === -1)
    totalAmountsCellsBySuppliers.push({
      supplier: supplierName,
      amounts: amountsData,
    })
  else {
    totalAmountsCellsBySuppliers[existingTotalIndex] = {
      ...totalAmountsCellsBySuppliers[existingTotalIndex],
      amounts: [...totalAmountsCellsBySuppliers[existingTotalIndex].amounts, ...amountsData],
    }
  }
  return newRowValue
}

function _addTotalTab(workbook, totalAmountsCellsBySuppliers) {
  const totalTurnoverCells = ['B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J']
  const totalSubsidiaryCells = ['K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S']
  const totalSas = ['T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'AA', 'AB']
  const totalCoop = ['AC', 'AD', 'AE', 'AF', 'AG', 'AH', 'AI', 'AJ', 'AK']
  const amountCells = [...totalTurnoverCells, ...totalSubsidiaryCells, ...totalSas, ...totalCoop]
  const allCells = ['A', ...amountCells]
  const supplierAmountsCells = [
    ...cellsAmounts,
    'M',
    'P',
    'Q',
    'R',
    'S',
    ...totalSas,
    ...totalCoop,
    'AL',
    'AM',
    'AN',
    'AO',
    'AP',
  ]
  const worksheet = workbook.addWorksheet('Total', { views: [{ showGridLines: false }] })
  /*** Titles */
  const headerCellsValues = {
    B: 'Total CA et volume déclarés',
    K: 'Total Coop Filiales',
    T: 'Total Coop SAS',
    AC: 'Total Coop Total',
  }
  addHeaderRow(worksheet, 1, headerCellsValues, [
    totalTurnoverCells[0],
    totalTurnoverCells[8],
    totalSubsidiaryCells[0],
    totalSubsidiaryCells[8],
    totalSas[0],
    totalSas[8],
    totalCoop[0],
    totalCoop[8],
  ])
  /*** Columns name */
  worksheet.columns = [{ width: 30 }, ...amountCells.map((_cell) => ({ width: 15 }))]
  const regions = REGIONS.map((region) => region.code)
  worksheet.addRow([
    '',
    ...regions,
    'Total CA déclaré',
    ...regions,
    'Total COOP Filiale',
    ...regions,
    'Total COOP SAS',
    ...regions,
    'Total COOP',
  ])
  totalTurnoverCells.forEach((cell) => setBackGroundColor(worksheet, `${cell}2`, 'FFFF00'))
  totalSubsidiaryCells.forEach((cell) => setBackGroundColor(worksheet, `${cell}2`, '92D050'))
  totalSas.forEach((cell) => setBackGroundColor(worksheet, `${cell}2`, 'EA9B56'))
  totalCoop.forEach((cell) => setBackGroundColor(worksheet, `${cell}2`, '4BACC6'))
  setBold(worksheet, 2)
  /*** Amounts */
  let suppliersTotalRowsFormula = ''
  let rowNumber = 2
  for (const total of totalAmountsCellsBySuppliers) {
    // Group cells number values, in case of several coop groups with the same name (because of multiple periods)
    const reducedAmountsByCoopGroup = []
    for (const amount of total.amounts) {
      if (reducedAmountsByCoopGroup.find((reducedAmount) => reducedAmount.coopGroup === amount.coopGroup)) {
        const index = reducedAmountsByCoopGroup.findIndex(
          (reducedAmount) => reducedAmount.coopGroup === amount.coopGroup
        )
        reducedAmountsByCoopGroup[index].cellsNumber.push(amount.cellNumber)
      } else reducedAmountsByCoopGroup.push({ coopGroup: amount.coopGroup, cellsNumber: [amount.cellNumber] })
    }
    // Add supplier row with SUM of coop groups amounts
    rowNumber++
    worksheet.addRow([
      total.supplier,
      ...amountCells.map((cell) => ({
        formula: `SUM(${cell}${rowNumber + 1}:${cell}${reducedAmountsByCoopGroup.length + rowNumber})`,
      })),
    ])
    setBold(worksheet, rowNumber)
    allCells.forEach((cell) => setCellNumberFormat(worksheet, cell, rowNumber))

    suppliersTotalRowsFormula = suppliersTotalRowsFormula
      ? `${suppliersTotalRowsFormula}+_${rowNumber}`
      : `_${rowNumber}`
    // Add coop group row
    for (const reducedTotalAmount of reducedAmountsByCoopGroup) {
      const cellsValue = supplierAmountsCells.map((cellLetter) => {
        let cellFormula = ''
        for (const cellNumber of reducedTotalAmount.cellsNumber) {
          if (cellFormula) cellFormula = `${cellFormula}+'${total.supplier}'!${cellLetter}${cellNumber}`
          else cellFormula = `'${total.supplier}'!${cellLetter}${cellNumber}`
        }
        return cellFormula
      })
      rowNumber++
      worksheet.addRow([reducedTotalAmount.coopGroup, ...cellsValue.map((cellValue) => ({ formula: cellValue }))])
      allCells.forEach((cell) => setCellNumberFormat(worksheet, cell, rowNumber))
    }
  }
  rowNumber++
  worksheet.addRow([
    'Total général',
    ...amountCells.map((cell) => ({ formula: suppliersTotalRowsFormula.replaceAll('_', cell) })),
  ])
  allCells.forEach((cell) => {
    setBackGroundColor(worksheet, `${cell}${rowNumber}`, totalColor)
    setCellNumberFormat(worksheet, cell, rowNumber)
  })
  setBold(worksheet, rowNumber)
}

export default async function (periods, suppliers) {
  const workbook = new Excel.Workbook()
  const sortedSuppliers = sortObjectsArray([...suppliers], 'name')
  const sortedPeriods = [...periods].sort()

  const totalAmountsCellsBySuppliers = []
  for (const supplier of sortedSuppliers) {
    if (supplier.statements[0].amounts.length === 0) continue
    const statementsByPeriods = sortedPeriods
      .map((period) => supplier.statements.find((statement) => statement.period === period))
      .filter((statement) => statement !== undefined)
    if (statementsByPeriods.length === 0) continue

    const supplierName = supplier.name.replace(/[:&().]/g, '').slice(0, 30) // reduce size of text + remove special chars to prevent excel error

    // Set tab name
    const worksheet = workbook.addWorksheet(supplierName, {
      views: [{ showGridLines: false }],
    })
    setColumnsWidth(worksheet)

    let row = 1
    let rowWithValues = []
    let currentAmounts = statementsByPeriods[0].amounts
    for (const statement of statementsByPeriods) {
      if (statement.amounts[0].coopGroup.agreementId !== currentAmounts[0].coopGroup.agreementId) {
        row = _setTotalSupplier(
          worksheet,
          row,
          rowWithValues,
          currentAmounts,
          supplierName,
          totalAmountsCellsBySuppliers
        )
        rowWithValues = []
        currentAmounts = statement.amounts
      }
      row = _createSupplierStatementsReport(worksheet, statement, row, rowWithValues)
    }
    const lastAmounts = statementsByPeriods[statementsByPeriods.length - 1].amounts
    row = _setTotalSupplier(worksheet, row, rowWithValues, lastAmounts, supplierName, totalAmountsCellsBySuppliers)
  }

  _addTotalTab(workbook, totalAmountsCellsBySuppliers)

  const buffer = await workbook.xlsx.writeBuffer()
  FileSaver.saveAs(new Blob([buffer]), `Rapport_des_déclarations.xlsx`)
}
