import { useState } from 'react'
import uploadFile from '../../../services/file/upload'
import { FILE_FORMATS, FILE_TYPES } from '../../../utils/constants'
import DialogInfo from '../../UI/DialogInfo'
import DragAndDropFile from '../../UI/DragAndDropFile'
import ValidationWrapper from '../../UI/Form/ValidationWrapper'

export default function SupplierUploadDialog({ supplierName, opened, onClose, onValidate }) {
  const [fileToUpload, setFile] = useState(null)

  function _handleFile(file) {
    setFile(file ? file : null)
  }

  async function _uploadFile() {
    if (!fileToUpload) return
    try {
      await uploadFile(fileToUpload, FILE_TYPES.annex, supplierName)
      onValidate({ status: false })
    } catch {
      onValidate({ status: true })
    } finally {
      onClose()
    }
  }

  return (
    <DialogInfo title="Importer un nouveau fichier d'accord" open={opened} onClose={onClose}>
      <ValidationWrapper fieldsValidity={{ file: fileToUpload !== null }} onValidate={_uploadFile}>
        <DragAndDropFile file={fileToUpload} accepted={FILE_FORMATS.excel} handleDrop={_handleFile} />
      </ValidationWrapper>
    </DialogInfo>
  )
}
