import { Box } from '@material-ui/core'
import PropTypes from 'prop-types'
import './GreyCard.css'

GreyCard.propTypes = {
  children: PropTypes.element.isRequired,
}

export default function GreyCard({ children }) {
  return <Box className="grey-card">{children}</Box>
}
