import * as msal from '@azure/msal-browser'

const msalConfig = {
  auth: {
    clientId: '1ad5c8b5-2be8-4c85-b3ee-fbc9099b59e4',
    authority: 'https://login.microsoftonline.com/66e853de-ece3-44dd-9d66-ee6bdf4159d4',
    redirectUri: '/',
  },
  cache: { cacheLocation: 'localStorage' },
  storeAuthStateInCookie: false,
}

const loginRequest = {
  scopes: ['User.Read'],
}

const apiScopeRequest = {
  scopes: ['api://7ef6a7dd-acd7-4428-823c-845c86f93ce0/access_as_user'],
}

const msalInstance = new msal.PublicClientApplication(msalConfig)

export { msalInstance, loginRequest, apiScopeRequest }
