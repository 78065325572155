import Excel from 'exceljs'

export async function getExcelWorksheet(file) {
  const workbook = new Excel.Workbook()
  await workbook.xlsx.load(file)
  return workbook.getWorksheet(1)
}

export function setBackGroundColor(worksheet, cell, color) {
  worksheet.getCell(cell).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: color } }
}

export function setFormat(worksheet, cell, format) {
  worksheet.getColumn(cell).numFmt = format
}

export function setBorder(worksheet, cell) {
  worksheet.getCell(cell).border = {
    top: { style: 'thin' },
    left: { style: 'thin' },
    bottom: { style: 'thin' },
    right: { style: 'thin' },
  }
}

export function setBold(worksheet, row, important = false) {
  worksheet.getRow(row).font = important ? { bold: true, size: 16 } : { bold: true }
}

export function setCellNumberFormat(worksheet, cell, row) {
  worksheet.getCell(`${cell}${row}`).numFmt = '### ### ###'
}
