import { Box, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import { FILE_FORMATS } from '../../../utils/constants'
import DragAndDropFile from '../../UI/DragAndDropFile'
import ValidationWrapper from '../../UI/Form/ValidationWrapper'
import Subtitle from '../../UI/SubTitle'
import StatementNoAgreementError from '../StatementNoAgreementError'

StatementUpload.propTypes = {
  file: PropTypes.object,
  isPeriodSelected: PropTypes.bool.isRequired,
  handleDrop: PropTypes.func.isRequired,
  hasAgreement: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default function StatementUpload({ file, isPeriodSelected, handleDrop, hasAgreement, title, onClick }) {
  return (
    <ValidationWrapper
      fieldsValidity={{ periods: isPeriodSelected, file: file !== null }}
      onValidate={() => onClick(2)}
    >
      <>
        <Subtitle text={title} />
        {hasAgreement ? (
          <>
            <Box mb={4}>
              <Typography variant="body2">
                Importer votre collecte.
                <br />
                Attention, le format de votre fichier doit strictement respecter le fichier original téléchargé en étape
                1.
              </Typography>
            </Box>
            <DragAndDropFile file={file} accepted={FILE_FORMATS.excel} handleDrop={handleDrop} />
          </>
        ) : (
          <StatementNoAgreementError />
        )}
      </>
    </ValidationWrapper>
  )
}
