export const saveStatement = (filePath, fileName, period, supplierId) => {
  return `mutation {
    saveStatement(
      filePath: "${filePath}",
      fileName: "${fileName}",
      period: "${period}",
      supplierId: ${supplierId}
    ) {
      id
      fileId
      supplierId
      isUpdate
    }
  }`
}
