import { Box, Grid } from '@material-ui/core'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import API from '../../../api/api'
import { createContractMutation } from '../../../api/mutations/contractMutation'
import { getAllBusinessUnitsQuery } from '../../../api/queries/businessUnitQueries'
import uploadFile from '../../../services/file/upload'
import { ERRORS, FILE_FORMATS, FILE_TYPES } from '../../../utils/constants'
import { numberInput, salesPointCodeInput, zipCodeInput } from '../../../utils/form/rules'
import { today } from '../../../utils/format/date'
import { sortObjectsArray } from '../../../utils/functions/common'
import DialogInfo from '../../UI/DialogInfo'
import DragAndDropFile from '../../UI/DragAndDropFile'
import CommonTextField from '../../UI/Form/CommonTextField'
import ValidationWrapper from '../../UI/Form/ValidationWrapper'

ContractDialog.propTypes = {
  supplier: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }),
  opened: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onValidate: PropTypes.func.isRequired,
}

export default function ContractDialog({ supplier, opened, onClose, onValidate }) {
  const [contract, setContract] = useState({
    businessUnitCode: '',
    salesPointCode: '',
    salesPointName: '',
    salesPointAddress: '',
    salesPointZipcode: '',
    salesPointCity: '',
    startDate: today(),
    endDate: today(),
    volume: '',
    volumeType: 1,
    file: null,
  })
  const [fieldsValidity, setFieldsValidity] = useState({
    businessUnitCode: false,
    salesPointCode: false,
    salesPointAddress: false,
    salesPointZipcode: false,
    salesPointCity: false,
    startDate: true,
    endDate: true,
    volume: false,
    volumeType: true,
  })
  const [businessUnits, setBusinessUnits] = useState([])
  const [isLoading, setLoading] = useState(false)
  const [errorDate, setErrorDate] = useState({
    error: false,
    message: ERRORS.ERROR_START_END_DATE,
  })

  useEffect(() => {
    if (businessUnits.length === 0) {
      API.queryAPI(getAllBusinessUnitsQuery()).then((result) => {
        const businessUnits = sortObjectsArray(
          result.businessUnits.map((bu) => {
            return { id: bu.id, value: bu.name }
          }),
          'value'
        )
        setBusinessUnits(businessUnits)
      })
    }

    checkDates()
  }, [contract.startDate, contract.endDate])

  function _handleChange(value, name, isValid) {
    setContract({ ...contract, [name]: value })
    setFieldsValidity({ ...fieldsValidity, [name]: isValid })
  }

  function checkDates() {
    contract.startDate > contract.endDate ? changeErrorDate(true) : changeErrorDate(false)
  }

  function changeErrorDate(hasError) {
    setErrorDate({ ...errorDate, error: hasError })
    setFieldsValidity({ ...fieldsValidity, endDate: !hasError, startDate: !hasError })
  }

  async function _handleValidate() {
    setLoading(true)
    try {
      const fileName = contract.file ? await uploadFile(contract.file, FILE_TYPES.contract, supplier.name) : ''
      const newContract = await API.queryAPI(createContractMutation(supplier.id, contract, fileName))
      onValidate(newContract.createContract, { status: false })
    } catch {
      onValidate({}, { status: true })
    } finally {
      setLoading(false)
      onClose()
    }
  }

  return (
    <DialogInfo title="Ajouter un nouveau contrat" open={opened} onClose={onClose}>
      <ValidationWrapper fieldsValidity={fieldsValidity} isLoading={isLoading} onValidate={_handleValidate}>
        <Box mt={3} mb={6}>
          <Grid container spacing={1}>
            <Grid item sm={4}>
              <CommonTextField
                name="businessUnitCode"
                value={contract.businessUnitCode}
                onChange={_handleChange}
                label="Site FB (BU)"
                required
                select={businessUnits}
              />
            </Grid>
            <Grid item sm={4}>
              <CommonTextField
                name="salesPointCode"
                value={contract.salesPointCode}
                onChange={_handleChange}
                label="Code client FB"
                required
                rules={salesPointCodeInput}
              />
            </Grid>
            <Grid item sm={4}>
              <CommonTextField
                name="salesPointName"
                value={contract.salesPointName}
                onChange={_handleChange}
                label="Nom du point de vente"
                required
              />
            </Grid>
            <Grid item sm={4}>
              <CommonTextField
                name="salesPointAddress"
                value={contract.salesPointAddress}
                onChange={_handleChange}
                label="Adresse du point de vente"
                required
              />
            </Grid>
            <Grid item sm={4}>
              <CommonTextField
                name="salesPointZipcode"
                value={contract.salesPointZipcode}
                onChange={_handleChange}
                label="Code postal du point de vente"
                required
                rules={zipCodeInput}
              />
            </Grid>
            <Grid item sm={4}>
              <CommonTextField
                name="salesPointCity"
                value={contract.salesPointCity}
                onChange={_handleChange}
                label="Ville du point de vente"
                required
              />
            </Grid>
            <Grid item sm={3}>
              <CommonTextField
                name="volume"
                label="Volume prévisionnel"
                onChange={_handleChange}
                rules={numberInput}
                required
              />
            </Grid>
            <Grid item sm={3}>
              <CommonTextField
                name="volumeType"
                label="Unité de volume"
                value={contract.volumeType}
                required
                onChange={_handleChange}
                select={[
                  { id: 0, value: 'Bouteilles' },
                  { id: 1, value: 'HL' },
                ]}
              />
            </Grid>
            <Grid item sm={3}>
              <CommonTextField
                name="startDate"
                value={contract.startDate}
                onChange={_handleChange}
                label="Date de début du contrat"
                type="date"
                customError={errorDate.error}
                customErrorMessage={errorDate.message}
                required
              />
            </Grid>
            <Grid item sm={3}>
              <CommonTextField
                name="endDate"
                value={contract.endDate}
                onChange={_handleChange}
                label="Date de fin du contrat"
                type="date"
                customError={errorDate.error}
                customErrorMessage={errorDate.message}
                required
              />
            </Grid>
          </Grid>
          <DragAndDropFile
            file={contract.file}
            accepted={FILE_FORMATS.image}
            handleDrop={(file) => _handleChange(file, 'file', true)}
          />
        </Box>
      </ValidationWrapper>
    </DialogInfo>
  )
}
