import { UserType } from '../types'

export const createSupplierMutation = (supplier, contact, backup) => {
  return `mutation {
    createSupplier(
      name: "${supplier.name.replace('/', ' ')}",
      address: "${supplier.address}",
      city: "${supplier.city}",
      zipCode: "${supplier.zipcode}",
      contact: ${contact},
      backupContact: ${backup},
      owner: ${supplier.owner},
    ) {
      id
      name
      ${UserType('owner')}
      ${UserType('contact')}
      ${UserType('backupContact')}
      regionUsers {
        id
        ${UserType('contact')}
        ${UserType('backupContact')}
      }
    }
  }`
}

export const updateSupplierContactMutation = (supplier, contact, isBackup, region) => {
  return `mutation {
    updateSupplierContact(
      id: ${supplier},
      contact: ${contact},
      isBackup: ${isBackup},
      region: ${region},
    ) {
      id
      name
      ${UserType('contact')}
      ${UserType('backupContact')}
      regionUsers {
        id
        ${UserType('contact')}
        ${UserType('backupContact')}
      }
    }
  }`
}

export const updateSupplierOwnerMutation = (supplier, ownerId) => {
  return `mutation {
    updateSupplierOwner(
      id: ${supplier},
      ownerId: ${ownerId},
    ) {
      id
      ${UserType('owner')}
    }
  }`
}
