import { Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import API from '../../api/api'
import { getAllSuppliersStatementsQuery } from '../../api/queries/supplierQueries'
import BodyLayout from '../../components/Layout/BodyLayout'
import LastStatementsTable from '../../components/Statement/LastStatementsTable'
import Alert from '../../components/UI/Alert'
import MainMenu from '../../components/UI/Menu/MainMenu'
import WelcomeBox from '../../components/UI/WelcomeBox'
import { useAlert } from '../../hooks/useAlert'
import { useErrorState } from '../../hooks/useErrorState'

export default function BackOffice() {
  const { alert, setReset } = useAlert()
  const { error, setErrorState } = useErrorState()
  const [suppliers, setSuppliers] = useState([])

  useEffect(() => {
    API.queryAPI(getAllSuppliersStatementsQuery)
      .then((suppliersResult) => setSuppliers(suppliersResult.suppliers))
      .catch((errorMessage) => setErrorState(errorMessage))
  }, [])

  const menu = [
    {
      title: 'Gestion',
      action: [
        { text: 'Fournisseurs', path: '/backoffice/suppliers' },
        { text: 'Paramétrage', path: '/backoffice/settings' },
      ],
    },
    {
      title: 'Données',
      action: [{ text: 'Historique', path: '/backoffice/history' }],
    },
  ]

  return (
    <div>
      <Alert message={alert.message} open={alert.open} success={alert.success} onClose={setReset} />
      {!error.status && <WelcomeBox subtitle="Bienvenue dans le portail de gestion des déclarations des CA" />}
      <BodyLayout error={error} menu={<MainMenu items={menu} />}>
        <>
          <Typography variant="h6" gutterBottom>
            Suivi des dernières déclarations
          </Typography>
          <LastStatementsTable suppliers={suppliers} />
        </>
      </BodyLayout>
    </div>
  )
}
