import { Grid } from '@material-ui/core'
import PropTypes from 'prop-types'
import FigureCards from '../../UI/FigureCards'

HistoryFigures.propTypes = {
  selectedTurnovers: PropTypes.array.isRequired,
  turnovers: PropTypes.array.isRequired,
}

export default function HistoryFigures({ selectedTurnovers, turnovers }) {
  const amount = (currentTurnovers, type) => {
    let amount = 0
    if (!currentTurnovers || currentTurnovers.length === 0) return amount
    return Math.round(currentTurnovers.reduce((total, current) => total + current[type], 0))
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <FigureCards
          title="Totaux"
          items={[
            { subtitle: "Chiffre d'affaires et volume", figure: amount(selectedTurnovers, 'amount') },
            { subtitle: "Remise en fin d'année", figure: amount(selectedTurnovers, 'discount') },
          ]}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FigureCards
          title="Cumul année"
          items={[
            { subtitle: "Chiffre d'affaires et volume", figure: amount(turnovers, 'amount') },
            { subtitle: "Remise en fin d'année", figure: amount(turnovers, 'discount') },
          ]}
        />
      </Grid>
    </Grid>
  )
}
