import { Box, Grid, Paper } from '@material-ui/core'
import PropTypes from 'prop-types'
import './login.css'

LoginLayout.propTypes = {
  children: PropTypes.element,
}

export default function LoginLayout({ children }) {
  return (
    <Grid container className="loginContainer">
      <Grid item sm={4}>
        <Paper elevation={0} className="loginVerticalBanner" />
      </Grid>
      <Grid container item xs={12} sm={8} direction="column">
        <Grid item sm={3}></Grid>
        <Grid item sm={6}>
          <Box ml={3}>
            <main>{children}</main>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  )
}
