import { Typography } from '@material-ui/core'
import { CloudUpload } from '@material-ui/icons'
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined'
import DescriptionIcon from '@material-ui/icons/Description'
import React, { createRef } from 'react'
import { FILE_FORMATS } from '../../../utils/constants'
import File from '../File'
import './DragAndDropFile.css'

export default class DragAndDropFile extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      dragging: false,
    }

    this.dropRef = createRef()
    this.inputFileRef = createRef()
  }

  _handleDrag = (e) => {
    // This event overrides default browser event
    e.preventDefault()
    e.stopPropagation()
  }

  _handleDragIn = (e) => {
    e.preventDefault()
    e.stopPropagation()

    // Handle nested elements
    this.dragCounter++

    // Check if the drag event has any file
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) this.setState({ dragging: true })
  }

  _handleDragOut = (e) => {
    e.preventDefault()
    e.stopPropagation()

    // Handle nested elements
    this.dragCounter--
    if (this.dragCounter > 0) return
    this.setState({ dragging: false })
  }

  _handleDrop = (e) => {
    e.preventDefault()
    e.stopPropagation()

    this.setState({ dragging: false })
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      this.props.handleDrop(e.dataTransfer.files[0])
      this.dragCounter = 0
    }
  }

  // Case the user wants to use the regular modal to choose the file
  _handleOnChange = (e) => {
    e.stopPropagation()
    e.preventDefault()

    if (e.target.files && e.target.files.length > 0) this.props.handleDrop(e.target.files[0])
  }

  componentDidMount() {
    // This counter handles nested elements. It's increased for each element hovered by the cursor and decreased on hover out
    this.dragCounter = 0
    let div = this.dropRef.current
    div.addEventListener('dragenter', this._handleDragIn)
    div.addEventListener('dragleave', this._handleDragOut)
    div.addEventListener('dragover', this._handleDrag)
    div.addEventListener('drop', this._handleDrop)
  }

  componentWillUnmount() {
    let div = this.dropRef.current
    div.removeEventListener('dragenter', this._handleDragIn)
    div.removeEventListener('dragleave', this._handleDragOut)
    div.removeEventListener('dragover', this._handleDrag)
    div.removeEventListener('drop', this._handleDrop)
  }

  _displayUploadIcon = () => {
    if (this.state.dragging) return <CloudUpload style={{ margin: '20px', fontSize: '80px' }} />
    if (this.props.file) return <DescriptionIcon style={{ margin: '20px', fontSize: '80px' }} />
    return <CloudUploadOutlinedIcon style={{ margin: '20px', fontSize: '80px' }} />
  }

  _fileAccepted = () => {
    switch (this.props.accepted) {
      case FILE_FORMATS.excel:
        return '.xls, .xlsx'
      case FILE_FORMATS.image:
        return 'image/*,.pdf'
      default:
        return '*'
    }
  }

  render() {
    const text = !this.state.dragging && this.props.file ? this.props.file.name : 'Déposer votre fichier ici'
    return (
      <div>
        <input
          id="uploadInput"
          type="file"
          accept={this._fileAccepted()}
          ref={this.inputFileRef}
          style={{ display: 'none' }}
          onChange={this._handleOnChange}
        />
        <div ref={this.dropRef}>
          <File
            onClick={() => {
              this.inputFileRef.current.click()
            }}
            icon={this._displayUploadIcon()}
          >
            <Typography variant={`${this.state.dragging ? 'overline' : 'caption'}`}>{text}</Typography>
          </File>
        </div>
      </div>
    )
  }
}
