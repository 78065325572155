import views from './pages'
import { BO_ROLES, SUPPLIERS_ROLES } from './utils/constants'

const BackOffice = {
  component: views.BackOffice,
  path: '/backoffice',
  protected: true,
  authRoles: BO_ROLES,
}

const BackOfficeContracts = {
  component: views.Contracts,
  path: '/backoffice/contracts',
  protected: true,
  authRoles: BO_ROLES,
}

const BackOfficeHistory = {
  component: views.History,
  path: '/backoffice/history',
  protected: true,
  authRoles: BO_ROLES,
}

const BackOfficeSettings = {
  component: views.Settings,
  path: '/backoffice/settings',
  protected: true,
  authRoles: BO_ROLES,
}

const BackOfficeSuppliers = {
  component: views.Suppliers,
  path: '/backoffice/suppliers/:id?',
  protected: true,
  authRoles: BO_ROLES,
}

const Contracts = {
  component: views.SupplierContracts,
  name: 'contract',
  path: '/contracts',
  protected: true,
  authRoles: SUPPLIERS_ROLES,
}

const History = {
  component: views.SupplierHistory,
  path: '/history',
  protected: true,
  authRoles: SUPPLIERS_ROLES,
}

const Home = {
  component: views.Home,
  path: '/',
  protected: true,
  authRoles: SUPPLIERS_ROLES,
}

const ResetPassword = {
  component: views.ResetPassword,
  path: '/resetPassword',
  protected: false,
}

const Statement = {
  component: views.Statement,
  path: '/statement',
  protected: true,
  authRoles: SUPPLIERS_ROLES,
}

const UpdatePassword = {
  component: views.UpdatePassword,
  path: '/updatePassword',
  protected: false,
}

export default [
  BackOffice,
  BackOfficeContracts,
  BackOfficeHistory,
  BackOfficeSettings,
  BackOfficeSuppliers,
  Contracts,
  History,
  Home,
  ResetPassword,
  Statement,
  UpdatePassword,
]
