export const ALERT_MESSAGE = { success: 'Données sauvegardées', error: 'Une erreur est survenue' }

export const ROLES = { owner: 1, BO: 2, supplier: 3, RAV: 4 }
export const BO_ROLES = [1, 2]
export const SUPPLIERS_ROLES = [3, 4, 5]
export const ROLES_VALUES = [
  { id: 1, value: 'Acheteur' },
  { id: 2, value: 'Back Office' },
]
export const REGIONS = [
  { id: 1, code: 'BN', name: 'Bretagne Normandie' },
  { id: 2, code: 'CRAE', name: 'Centre Rhone Alpes' },
  { id: 3, code: 'IDF', name: 'Ile de France' },
  { id: 4, code: 'LARZABAL', name: 'Larzabal' },
  { id: 5, code: 'SOGEDIS', name: 'Sogedis' },
  { id: 6, code: 'LSO', name: 'Loire Sud Ouest' },
  { id: 7, code: 'NORD', name: 'Nord' },
  { id: 8, code: 'SE', name: 'Sud Est' },
]

export const CONTRACT_STATUS = {
  pending: { id: 1, capsuleColor: '#FFECCC', color: '#CE8500' },
  validated: { id: 2, capsuleColor: '#CDFFCD', color: '#205527' },
  refused: { id: 3, capsuleColor: '#FFE0E0', color: '#D30000' },
  done: { id: 4, capsuleColor: '#CDCDFF', color: '#00007F' },
}

export const FILE_FORMATS = {
  excel: 'excel',
  image: 'image',
}

export const FILE_TYPES = {
  annex: 'annex',
  statement: 'statement',
  contract: 'contract',
  doc: 'doc',
}

export const PROCESS_STATEMENTS_FILE = 'modop portail fournisseurs ca.pdf'

export const MONTHS = [
  'janvier',
  'février',
  'mars',
  'avril',
  'mai',
  'juin',
  'juillet',
  'août',
  'septembre',
  'octobre',
  'novembre',
  'décembre',
]

export const ERRORS = {
  ERROR_START_END_DATE: 'La date de début doit être antérieure à la date de fin',
}
