import API from '../../api/api'
import { updateContractStatusMutation } from '../../api/mutations/contractMutation'

export async function handleContractSatusValidation(contract, statusId, message = null) {
  try {
    const result = await API.queryAPI(updateContractStatusMutation(contract.id, statusId, message))
    return { newContract: { ...contract, status: result.updateContractStatus.status }, error: { status: false } }
  } catch (errorMessage) {
    return { newContract: {}, error: { status: true, message: errorMessage } }
  }
}
