import { Box, Grid, IconButton } from '@material-ui/core'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import EditIcon from '@material-ui/icons/Edit'
import PropTypes from 'prop-types'
import { upperFirstLetter } from '../../../utils/format/text'
import GreyCard from '../GreyCard'
import Title from '../Title'

UserCard.propTypes = {
  users: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  onAddUser: PropTypes.func.isRequired,
  onEditUser: PropTypes.func.isRequired,
}

export default function UserCard({ users, title, onAddUser, onEditUser }) {
  return (
    <Box mb={5}>
      <Title text={title} variant="body2" className="menu" />
      <Grid container spacing={2}>
        {users.map((user, index) => (
          <Grid key={index} item xs={12} sm={6}>
            {Object.entries(user).length > 0 ? (
              <GreyCard>
                <>
                  <Box display="flex">
                    <Box width="100%" mr={1}>
                      {upperFirstLetter(user.firstname)}
                    </Box>
                    <Box width="100%">{upperFirstLetter(user.lastname)}</Box>
                    <Box>
                      <IconButton
                        aria-label="modifier"
                        color="primary"
                        onClick={() => onEditUser(user, index === 1)}
                        size="small"
                      >
                        <EditIcon fontSize="inherit" />
                      </IconButton>
                    </Box>
                  </Box>
                  <Box mt={2}>{user.email}</Box>
                </>
              </GreyCard>
            ) : (
              <Box display="flex" justifyContent="center" alignItems="center" width="100%" height={92}>
                <IconButton aria-label="ajouter un utilisateur" color="inherit" onClick={() => onAddUser(index === 1)}>
                  <AddCircleIcon fontSize="large" color="primary" />
                </IconButton>
              </Box>
            )}
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}
