import { Box, Button, CircularProgress } from '@material-ui/core'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'

ValidationWrapper.propTypes = {
  button: PropTypes.string,
  children: PropTypes.element.isRequired,
  fieldsValidity: PropTypes.object,
  isLoading: PropTypes.bool,
  onValidate: PropTypes.func.isRequired,
}

ValidationWrapper.defaultProps = {
  button: 'Valider',
  fieldsValidity: { valid: true },
  isLoading: false,
}

export default function ValidationWrapper({ button, children, fieldsValidity, isLoading, onValidate }) {
  const [disabled, setDisabled] = useState(true)

  useEffect(() => {
    setDisabled(Object.values(fieldsValidity).includes(false))
  }, [fieldsValidity])

  return (
    <>
      {children}
      <Box display="flex" justifyContent="flex-end">
        <Button
          variant="contained"
          onClick={onValidate}
          color="secondary"
          autoFocus
          disabled={isLoading ? isLoading : disabled}
          endIcon={isLoading ? <CircularProgress style={{ height: '20px', width: '20px' }} /> : ''}
        >
          {button}
        </Button>
      </Box>
    </>
  )
}
