import { MenuItem, TextField } from '@material-ui/core'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'

CommonTextField.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  select: PropTypes.array,
  customError: PropTypes.bool,
  customErrorMessage: PropTypes.string,
  rules: PropTypes.object,
  onChange: PropTypes.func.isRequired,
}
CommonTextField.defaultProps = {
  type: 'text',
  disabled: false,
  required: false,
  select: [],
  customError: false,
  rules: {},
}

export default function CommonTextField({
  name,
  value,
  label,
  type,
  disabled,
  required,
  select,
  rules,
  customError,
  customErrorMessage,
  onChange,
}) {
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    setError(customError)
    if (customError) setErrorMessage(customErrorMessage)
  }, [customError])

  function _handleOnChange(e) {
    const isValid = select.length > 0 ? e.target.value !== '' : e.target.checkValidity()
    if (!customError) {
      setError(!isValid)
      setErrorMessage(e.target.validationMessage)
    }
    onChange(e.target.value, e.target.name, isValid)
  }

  return (
    <TextField
      id={name}
      name={name}
      value={value}
      onChange={_handleOnChange}
      label={label}
      type={type}
      margin="normal"
      variant="outlined"
      fullWidth
      disabled={disabled}
      required={required}
      error={error}
      helperText={errorMessage}
      select={select.length > 0}
      inputProps={rules}
      InputLabelProps={{
        shrink: true,
      }}
    >
      {select.length > 0 &&
        select.map((item) => (
          <MenuItem key={item.id} value={item.id}>
            {item.value}
          </MenuItem>
        ))}
    </TextField>
  )
}
