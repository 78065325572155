import { Box, Checkbox, Divider, FormControl, FormControlLabel, FormHelperText, Grid } from '@material-ui/core'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { CONTRACT_STATUS } from '../../../utils/constants'
import DialogInfo from '../../UI/DialogInfo'
import ValidationWrapper from '../../UI/Form/ValidationWrapper'
import { handleContractSatusValidation } from '../common'

ContractValidationDialog.propTypes = {
  contract: PropTypes.object.isRequired,
  opened: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onValidate: PropTypes.func.isRequired,
}

export default function ContractValidationDialog({ contract, opened, onClose, onValidate }) {
  const [state, setState] = useState({
    details: false,
    readable: false,
    signed: false,
    completed: false,
    dates: false,
    businessUnit: false,
  })
  const { details, readable, signed, completed, dates, businessUnit } = state
  const error = Object.values(state).includes(false)

  const _handleChange = (event) => setState({ ...state, [event.target.name]: event.target.checked })

  const _handleValidate = async (contract, statusId) => {
    const { newContract, error } = await handleContractSatusValidation(contract, statusId)
    onValidate(newContract, error)
    onClose()
  }

  return (
    <DialogInfo title="Valider un contrat" open={opened} onClose={onClose}>
      <ValidationWrapper
        fieldsValidity={state}
        onValidate={() => _handleValidate(contract, CONTRACT_STATUS.validated.id)}
      >
        <Box mb={2}>
          <Box mb={2}>
            Vous êtes sur le point de valider un contrat. Avant cela, vérifiez les points suivants et assurez-vous que
            tout est conforme :
          </Box>
          <FormControl required error={error} component="fieldset" style={{ margin: 3 }}>
            <Grid container>
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={<Checkbox checked={details} onChange={_handleChange} name="details" />}
                  label={`Le document concerne bien le client ${contract.salesPoint.name} à ${contract.salesPoint.address.city}`}
                />
                <FormControlLabel
                  control={<Checkbox checked={signed} onChange={_handleChange} name="signed" />}
                  label="Le document comporte bien la signature de toutes les parties"
                />
                <FormControlLabel
                  control={<Checkbox checked={dates} onChange={_handleChange} name="dates" />}
                  label="Les dates du contrat correspondent bien à la période indiquée"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={<Checkbox checked={readable} onChange={_handleChange} name="readable" />}
                  label="Le document est bien un fichier lisible"
                />
                <FormControlLabel
                  control={<Checkbox checked={completed} onChange={_handleChange} name="completed" />}
                  label="Le document comporte toutes les mentions nécessaires"
                />
                <FormControlLabel
                  control={<Checkbox checked={businessUnit} onChange={_handleChange} name="businessUnit" />}
                  label="La BU est bien celle concernée par le contrat"
                />
              </Grid>
            </Grid>
            <FormHelperText>Toutes les cases doivent être cochées</FormHelperText>
          </FormControl>
          <Divider />
          <Box my={2}>
            Une fois ces points vérifiés, vous pouvez valider le contrat. Son statut passera alors en &quot;Validé&quot;
            et un e-mail sera envoyé au fournisseur afin de l&lsquo;informer.
          </Box>
        </Box>
      </ValidationWrapper>
    </DialogInfo>
  )
}
