import { Box, Divider, IconButton, Typography } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBackIos'
import PropTypes from 'prop-types'
import React from 'react'
import { useHistory } from 'react-router-dom'

NavDetails.propTypes = {
  info: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  name: PropTypes.string.isRequired,
}

export default function NavDetails({ info, name }) {
  const history = useHistory()
  return (
    <Box display="flex" alignItems="center" pr={1} mb={2} border={1} borderColor="grey.300">
      <Box>
        <IconButton aria-label="back" onClick={() => history.goBack()}>
          <ArrowBackIcon fontSize="small" />
        </IconButton>
      </Box>

      <Divider orientation="vertical" flexItem />
      <Box flexGrow={1} ml={2}>
        <Typography variant="h6">{name}</Typography>
      </Box>
      <Box>{info}</Box>
    </Box>
  )
}
