import { Box, Button } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import PropTypes from 'prop-types'
import { useState } from 'react'
import Subtitle from '../../UI/SubTitle'
import AddSupplierDialog from '../AddSupplierDialog'
import SuppliersSettingsTable from '../SuppliersSettingsTable'

SuppliersSettingsView.propTypes = {
  suppliers: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  onAddSupplier: PropTypes.func.isRequired,
  onSupplierClick: PropTypes.func.isRequired,
}

export default function SuppliersSettingsView({ suppliers, title, onAddSupplier, onSupplierClick }) {
  const [openAddSupplierDialog, setOpenAddSupplier] = useState(false)

  return (
    <>
      <Box display="flex">
        <Box flexGrow={1}>
          <Subtitle text={title} />
        </Box>
        <Box>
          <Button
            startIcon={<AddIcon />}
            variant="contained"
            color="secondary"
            onClick={() => setOpenAddSupplier(true)}
          >
            Ajouter un fournisseur
          </Button>
        </Box>
      </Box>
      <SuppliersSettingsTable suppliers={suppliers} onEditClick={onSupplierClick} />
      {openAddSupplierDialog && (
        <AddSupplierDialog
          opened={openAddSupplierDialog}
          onClose={() => setOpenAddSupplier(false)}
          onValidate={onAddSupplier}
        />
      )}
    </>
  )
}
