import { getLastPeriodArray, getPeriodsByYears } from '../../../utils/functions/history'

const getFilteredFiles = (files, lastPeriod, suppliersId) =>
  files.filter((file) => suppliersId.includes(file.supplierId) && file.period === lastPeriod)
const getFilteredTurnovers = (turnovers, lastPeriod, suppliersId) =>
  turnovers.filter((turnover) => suppliersId.includes(turnover.supplierId) && turnover.period === lastPeriod)

export const getFiltersByMultipleSuppliers = (files, suppliers, suppliersId, turnovers) => {
  const selectedSuppliers = suppliers.filter((supplier) => suppliersId.includes(supplier.id))
  const periodsBySuppliers = [...new Set(selectedSuppliers.map((supplier) => supplier.periods).flat())].sort()
  const periodsByYear = getPeriodsByYears(periodsBySuppliers)
  const [lastPeriod] = getLastPeriodArray(periodsByYear)
  const filteredFiles = getFilteredFiles(files, lastPeriod, suppliersId)
  const filteredTurnovers = getFilteredTurnovers(turnovers, lastPeriod, suppliersId)
  return { filteredFiles, filteredPeriods: periodsByYear, filteredTurnovers }
}

export const getFiltersBySupplier = (files, periods, suppliers, supplierId, turnovers) => {
  const selectedSupplier = suppliers.find((supplier) => supplier.id === supplierId)
  const filteredPeriods = periods
    .map((year) => ({
      ...year,
      data: year.data.filter((period) => selectedSupplier.periods.includes(period.id)),
    }))
    .filter((year) => year.data.length > 0)
  const [lastPeriod] = getLastPeriodArray(filteredPeriods)
  const filteredFiles = getFilteredFiles(files, lastPeriod, [supplierId])
  const filteredTurnovers = getFilteredTurnovers(turnovers, lastPeriod, [supplierId])
  return { filteredFiles: filteredFiles, filteredPeriods, filteredTurnovers }
}
