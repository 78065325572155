import { Grid } from '@material-ui/core'
import CommonTextField from '../CommonTextField'

export default function UserForm({ user, onChange }) {
  return (
    <>
      <Grid item xs={12} sm={6}>
        <CommonTextField name="firstname" value={user.firstname} onChange={onChange} label="Prénom" required />
      </Grid>
      <Grid item xs={12} sm={6}>
        <CommonTextField name="lastname" value={user.lastname} onChange={onChange} label="Nom" required />
      </Grid>
      <Grid item xs={6}>
        <CommonTextField name="email" value={user.email} onChange={onChange} label="E-mail" type="email" required />
      </Grid>
    </>
  )
}
