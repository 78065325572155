import { FormHelperText, Grid } from '@material-ui/core'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import API from '../../../api/api'
import { updateSupplierContactMutation } from '../../../api/mutations/supplierMutations'
import { createUserMutation, updateUserMutation } from '../../../api/mutations/userMutations'
import { getUserByRoleQuery } from '../../../api/queries/userQueries'
import DialogInfo from '../../UI/DialogInfo'
import UserForm from '../../UI/Form/UserForm'
import ValidationWrapper from '../../UI/Form/ValidationWrapper'
import UsersSelect from '../../UI/UsersSelect'

AddSupplierContactDialog.propTypes = {
  isBackup: PropTypes.bool.isRequired,
  role: PropTypes.number.isRequired,
  region: PropTypes.number,
  opened: PropTypes.bool.isRequired,
  supplier: PropTypes.object.isRequired,
  userToEdit: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  onValidate: PropTypes.func.isRequired,
}

const initialContact = () => ({ firstname: '', lastname: '', email: '' })
const initialValidity = () => ({ firstname: false, lastname: false, email: false })
export default function AddSupplierContactDialog({
  isBackup,
  role,
  region,
  opened,
  supplier,
  userToEdit,
  onClose,
  onValidate,
}) {
  const [existingContacts, setExistingContacts] = useState([])
  const [contact, setContact] = useState(
    !userToEdit
      ? initialContact
      : { firstname: userToEdit.firstname, lastname: userToEdit.lastname, email: userToEdit.email }
  )
  const [fieldsValidity, setFieldsValidity] = useState(
    !userToEdit ? initialValidity : { firstname: true, lastname: true, email: true }
  )

  useEffect(() => {
    API.queryAPI(getUserByRoleQuery(role)).then((contacts) => setExistingContacts(contacts.userByRole))
  }, [])

  function _handleChange(value, name, isValid) {
    setContact({ ...contact, [name]: value })
    setFieldsValidity({ ...fieldsValidity, [name]: isValid })
  }

  async function _handleNewContact() {
    try {
      let id = contact.hasOwnProperty('id') ? contact.id : null
      if (id === null) {
        const newContact = await API.queryAPI(
          createUserMutation({
            firstname: contact.firstname.trim().toLowerCase(),
            lastname: contact.lastname.trim().toLowerCase(),
            email: contact.email.trim().toLowerCase(),
            role: role,
          })
        )
        id = newContact.createUser.id
      }
      const updatedSupplier = await API.queryAPI(updateSupplierContactMutation(supplier.id, id, isBackup, region))
      onValidate(updatedSupplier.updateSupplierContact, { status: false })
    } catch (errorMessage) {
      onValidate({}, { status: true, message: errorMessage })
    }
  }

  async function _handleContactUpdate() {
    try {
      await API.queryAPI(
        updateUserMutation({
          firstname: contact.firstname.trim().toLowerCase(),
          lastname: contact.lastname.trim().toLowerCase(),
          email: contact.email.trim().toLowerCase(),
          id: userToEdit.id,
        })
      )
      if (region) {
        const regionIndex = supplier.regionUsers.findIndex((userRegion) => userRegion.id === region)
        const editedSupplier = supplier
        editedSupplier.regionUsers[regionIndex] = {
          ...editedSupplier.regionUsers[regionIndex],
          [isBackup ? 'backupContact' : 'contact']: { ...contact, id: userToEdit.id },
        }
        onValidate(editedSupplier, { status: false })
      } else
        onValidate(
          { ...supplier, [isBackup ? 'backupContact' : 'contact']: { ...contact, id: userToEdit.id } },
          { status: false }
        )
    } catch (errorMessage) {
      onValidate({}, { status: true, message: errorMessage })
    }
  }

  return (
    <DialogInfo
      title={!userToEdit ? 'Ajouter un nouveau contact' : 'Modifier le contact'}
      open={opened}
      onClose={onClose}
    >
      <ValidationWrapper
        fieldsValidity={fieldsValidity}
        onValidate={!userToEdit ? _handleNewContact : _handleContactUpdate}
      >
        <Grid container spacing={2}>
          {!userToEdit && (
            <Grid container item xs={12}>
              <Grid item xs={12} sm={6}>
                <UsersSelect
                  none
                  items={existingContacts}
                  label="Contacts"
                  onUserChange={(contactId) => {
                    setContact(
                      contactId ? existingContacts.find((contact) => contact.id === contactId) : initialContact
                    )
                    setFieldsValidity(contactId ? { firstname: true, lastname: true, email: true } : initialValidity)
                  }}
                />
                <FormHelperText>Vous pouvez choisir un contact existant ou bien en créer un nouveau</FormHelperText>
              </Grid>
            </Grid>
          )}
          <UserForm user={contact} onChange={_handleChange} />
        </Grid>
      </ValidationWrapper>
    </DialogInfo>
  )
}
